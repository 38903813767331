import axios from 'axios';

//const SERVERURL = 'https://apitools.rotaryd2232ukraine.org';
const SERVERURL = 'http://localhost:3001';

export const apiText2Audio = async (text, filename, voice, speed, pitch) => {
  const response = await axios.post(SERVERURL + '/openai/openai_text2audio', { text, filename, voice, speed, pitch });
  console.log(`apiText2Audio res=${JSON.stringify(response.data)}`);
  //const data = response.data;
  return response;
};

export const apiText2Audio_google = async (text, filename, voice, speed, pitch) => {
    const response = await axios.post(SERVERURL + '/openai/openai_text2audio_google', { text, filename, voice, speed, pitch });
    console.log(`apiText2Audio res=${JSON.stringify(response.data)}`);
    //const data = response.data;
    return response;
  };

  