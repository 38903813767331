import {getMenuStructure} from '../bdFunctions/getMenuStructure';
import {getPagesContent} from '../bdFunctions/getPagesContent';
import {getUserPagesContent} from '../bdFunctions/getUserPagesContent';
import {getPagesContent_projects} from '../bdFunctions/getPagesContent_projects';
import {getAdminPage} from '../bdFunctions/getAdminPage';
import RcClubPageMain from './RcClubPageMain';

const RcEClubOfUkrainePage = () => {
    const  designType='chicago';

    return (
        <RcClubPageMain 
            menufunc={getMenuStructure} 
            pagesContent={getPagesContent}
            getUserPagesContent={getUserPagesContent}
            getPagesContent_projects={getPagesContent_projects}
            getAdminPage={getAdminPage}
            designType={designType}
            tags='rec_newlevel'
        >
        </RcClubPageMain>
    );
}

export default RcEClubOfUkrainePage;