import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Card, CardContent, CardActions, Button, Paper } from '@mui/material';
import moment from 'moment';
import BlueAvatar from './components/BlueAvatar';

import LinkIcons from './components/LinkIcons';

import {apiGetEvents} from '../../api_sequelize/api_events';
import {getSubfieldValue} from '../../modules/CRUD_system/functions/MetafieldEditLineTools';

const CalendarCards = ({ filters, setEventCounts }) => {
  const [events, setEvents] = useState([]);
  const lastPastEventRef = useRef(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const fetchedEvents = await apiGetEvents();

        // Обробка та фільтрація подій
        const filteredEvents = fetchedEvents.filter(event => {
          if (filters.district && event.colorId === '1') return true;
          if (filters.club && event.colorId === '2') return true;
          if (filters.governor && event.colorId === '3') return true;
          return false;
        });

        // Сортування подій за датою початку
        filteredEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

        setEvents(filteredEvents);

        // Оновлення кількості подій кожного типу
        const eventCounts = {
          district: fetchedEvents.filter(event => event.colorId === '1').length,
          club: fetchedEvents.filter(event => event.colorId === '2').length,
          governor: fetchedEvents.filter(event => event.colorId === '3').length,
        };
        setEventCounts(eventCounts);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, [filters, setEventCounts]);

  useEffect(() => {
    if (lastPastEventRef.current) {
      lastPastEventRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [events]);

  const formatDate = (date) => moment(date).format('DD.MM.YYYY');
  const isPastEvent = (endDate) => moment(endDate).isBefore(moment(), 'day');

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {events.map((event, index) => {
          const pastEvent = isPastEvent(event.end);
          const isLastPastEvent = pastEvent && (index === events.length - 1 || !isPastEvent(events[index + 1]?.end));

          return (
            <Card
              key={event.id}
              ref={isLastPastEvent ? lastPastEventRef : null}
              sx={{
                width: 300,
                backgroundColor: pastEvent ? 'gray' : 'white',
                color: pastEvent ? 'lightgray' : 'black'
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ color: pastEvent ? 'lightgray' : 'darkblue' }}>
                  {event.title}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, pt: '8px' }}>
                    <BlueAvatar colorId={event.colorId} pastEvent={pastEvent} />
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body2" sx={{ color: pastEvent ? 'lightgray' : 'blue' }}>
                        {formatDate(event.start)} - {formatDate(event.end)}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: 1 }}>
                        {event.description || '-'}
                        </Typography>
                    </Box>
                </Box>
                {event.organizer && (
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    Організатор: {event.organizer.displayName}
                  </Typography>
                )}
              </CardContent>
                <CardActions>
                    <LinkIcons 
                        regicon={getSubfieldValue(event, 'additionalFields:regLink')} 
                        webicon={getSubfieldValue(event, 'additionalFields:siteLink')} 
                        docsicon={getSubfieldValue(event, 'additionalFields:docsLink')} 
                        photoicon={getSubfieldValue(event, 'additionalFields:photoLink')} 
                        videoicon={getSubfieldValue(event, 'additionalFields:videoLink')} 
                        pastEvent={pastEvent}
                    />
                </CardActions>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default CalendarCards;
