import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from 'react-router-dom';

import { LOCALES } from '../../../i18n/locales';
import {LocalContext} from '../../../tools/LanguageContextProvider';

import { getSiteById } from '../../../api_sequelize/api_sites';
import { getSiteMenusBySiteId } from '../../../api_sequelize/api_sites';
// import LandingPageV4 from '../components/LandingPageV4';
// import {getEven2Menu} from './getEven2Menu';
// import {getEvent3Landing} from './getEvent3Landing';
// import {getHeaderV4} from './getHeaderV4';
import RcClubPageMain_BD from '../ClubPageComponents/RcClubPageMain_BD';

export const getMenuStructure = (site, menuarr) => {
    const data = site && {
        navbar: {
            title: site.title,
            logoUrl: site.logourl,
            languages: site.languages,
            addlogoborder: true,
            withoutd2232url: true,
            menu: menuarr && menuarr.map((menu) => ({
                title: menu.btnText,
                lref: menu.btnUrl,
            }))
        }
    };
    return data;
}

const RotariadaPage = () => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [siteData, setSiteData] = useState(null);
    const [siteMenuArr, setSiteMenuArr] = useState(null);
    const [menujsondata, setMenujsondata] = useState(null);
    const siteId = 1;

    useEffect(() => {
        const fetchData = async () => {
          try {
              await loadData(currentLocale);
          } catch (error) {
            console.log("getHomeLanding get list error="+error);
          }
        };
        fetchData();
    }, [])

    const loadData = async(locale) => {
        const site = await getSiteById(siteId);
        setSiteData(site);
        console.log("site.id="+site.id);
        if (site && site.id) {
            const sMunu = await getSiteMenusBySiteId(site.id);
            setSiteMenuArr( sMunu );
            console.log("sMunu="+sMunu);

            setMenujsondata(getMenuStructure(site, sMunu));
        }
    }

    return (
        <>
        {/* RotariadaPage<br/>
        siteData={JSON.stringify(siteData)}<br/>
        siteData.id={siteData ? siteData.id : 'N/A'}<br/><br/>
        siteMenuArr={JSON.stringify(siteMenuArr)}<br/><br/>
        getMenuStructure={JSON.stringify(menujsondata)}<br/> */}
        {siteData && siteMenuArr && 
        <RcClubPageMain_BD 
            menudata={menujsondata} 
            designType={siteData.designtype}
        />}
        
        </>
  );
}

export default RotariadaPage;