import {fetchSitePage} from '../../../../api/api_sitecreator_rc_eclub';
export const getPagesContent = async (local) => {
    return data_eng;
    //return await fetchSitePage(1);
}

const data_eng = [

    {
        id: 0,
        _header: 'Photo collage',
        arrcontent: [
            {
                PhotoCarusel_15x10: [
                    "https://7fc65ea596c38701.cdn.express/secure/17KXIN9kDcsnp53tcLG1Gw/clubsitecreator/fs_iyfr/other_photo/0-02-01-35289c265ce1fe3629c43d9aec8d02e6fe97dc4ef2929d84d166ce918474a377_98d254dd03727e8c.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/ccypJxlzvnFsxXN01gNumg/clubsitecreator/fs_iyfr/other_photo/0-02-05-e822ba859c3765cf88ec9a8f4347604c2dbf474580165626ac865e8dd109ec15_210da6e3edb4350a.jpg",
                ],
            },
        ],
    },

    {
        id: 1,
        img: "https://7fc65ea596c38701.cdn.express/secure/9d-DRzV-jV3cQ3KDv6HXbg/clubsitecreator/fs_iyfr/other_photo/0-02-01-b12c9a96022ae5a66e8ba152ce110f6d221c3cb34f5cd096504ed9e0d85a3ce7_2195c43527d95392.jpg",
        header: `Запрошуємо!`,
        content: [
            {LandingMessage: `На Щорічні Загальні Збори
Спільна Сесія
02 серпня 2024
Миколаїв, Україна`},
            
        ],
    },
    {
        id: 1,
        header: `Співорганізатори AGM`,
        content: [
            {LandingImage: "true", link: "https://7fc65ea596c38701.cdn.express/secure/a9iCv42RXv87KppsOL3Zsw/clubsitecreator/fs_iyfr/other_photo/0-02-05-954ebee8074d658188c6992f622c7a6d415041b5ec623a899f51093a21e45aad_f001f8ee26094f11.jpg"},
            {LandingMessage: `ДГЕ Ольга Палійчук
Голова Комітету міжнародної діяльності, Регіональний Командор Міжнародного Ротарійського Яхтового Товариства - IYFR Region Ukraine 
РК Черкаси`},
            {LandingImage: "true", link: "https://7fc65ea596c38701.cdn.express/secure/VhwJmyqnV4MUY_RT3hsTSQ/clubsitecreator/fs_iyfr/other_photo/0-02-05-99f84be33dd738b780acf3543e0f2b7b98de0670af41faf4dcc3190a20291e0f_4a4d5e81f6b44831.jpg"},    
            {LandingMessage: `Андрій Черепанов
Командор Чорноморського Яхтового Ротарійського Флоту IYFR Black Sea Ukrainian Fleet Андрій Черепанов 
РК Миколаїв`},
        ],
    },
    {
        id: 1,
        header: `Спікери`,
        content: [
            {LandingImage: "true", link: "https://7fc65ea596c38701.cdn.express/secure/41qlL25ko-xQK2FJ21kMLQ/clubsitecreator/fs_iyfr/rotarians/lukach.jpg"},
            {LandingMessage: `Ростислав Лукач 
модератор навчального вебінара - тренер-фасілітатор Комітету ротарійських навчань Д2232 
РК Київ-Столиця`},
            {LandingImage: "true", link: "https://7fc65ea596c38701.cdn.express/secure/AiLGBUpLFDpP73L_OBm4zA/clubsitecreator/fs_iyfr/rotarians/hardin.jpg"},    
            {LandingMessage: `Губернатор Дистрикту 
Олександр Хардін 
РК Полтава`},
            {LandingImage: "true", link: "https://7fc65ea596c38701.cdn.express/secure/cHvZjloTA3VoL1dbIjxLvw/clubsitecreator/fs_iyfr/rotarians/dirk.jpg"},    
            {LandingMessage: `Міжнародна Ротарійська Група Дії з миробудування (RAGFP - Rotary Action Group for Peace), 
                Директор RAGFP в країнах ЄС 
                Дирк Люстиг 
                (РК Київ-Столиця)`},
            
            {LandingImage: "true", link: "https://7fc65ea596c38701.cdn.express/secure/eosuow7pYf7lSox4VENcFg/clubsitecreator/fs_iyfr/rotarians/odncova.jpg"},    
            {LandingMessage: ` Голова нового Міжнародного Ротарійського Товариства Освітян (IRFE - International Rotary Fellowship of Educators) 
                Ірина Одинцова 
                (РК Дніпро-Новий)`},

            
            {LandingImage: "true", link: "https://7fc65ea596c38701.cdn.express/secure/a-uPtCjLL0RfeKPn1xnIdw/clubsitecreator/fs_iyfr/rotarians/yuzef.jpg"},    
            {LandingMessage: `Міжнародне Ротарійське Товариство Віскі. Засновник та Голова української секції ротарійського віскі-клуба
                Юзеф Броневский 
                (РК Харків)`},

        ],
    },
    {
        id: 1,
        header: `Запрошуємо!`,
        content: [
            {LandingImage: "true", fullsize: true, link: "https://7fc65ea596c38701.cdn.express/secure/wcQvC5apwksBw04Jqtin6g/clubsitecreator/fs_iyfr/other_photo/0-02-01-7c9c2477a53a24e84fa0379f66ca5bffd791fcab9dd51387bffa4e7dd67a4c50_22d7077b68e53918.jpg"},
            {LandingMessage: `Онлайн навчальний вебінар «Ротарійські Товариства та Групи Дії - ефективний інструмент посилення міжнародної діяльності та реалізації проєктів служіння».
Дата проведення - пʼятниця, 02.08.2024, 19.00-20.30, онлайн формат.`},
            {LandingLink: 'more-fellowships', link: 'https://www.rotary.org/en/our-programs/more-fellowships'},
            {LandingMessage: `
Join Zoom Meeting  `},
            {LandingLink: 'zoom', link: 'https://us02web.zoom.us/j/88939590083'},
            {LandingMessage: `Meeting ID: 889 3959 0083

Модератор вебінара - тренер-фасілітатор Комітету ротарійських навчань Д2232 Ростислав Лукач (РК Київ-Столиці).`},
            {LandingEventProgramTable: `Програма заходу`, program: 
                [
                      {
                        "date": "02.08.2024",
                        "events": [
                          {
                            "time": "",
                            "details": "Прибуття учасників і гостей"
                          },
                          {
                            "time": "15:00-16:00",
                            "details": `Відвідування Музею Суднобудування і Флоту. 
Зустріч з екіпажем яхти «Ікар».`,
                            site1: 'https://uk.m.wikipedia.org/wiki/%D0%86%D0%BA%D0%B0%D1%80_(%D1%8F%D1%85%D1%82%D0%B0)'
                          },
                          {
                            "time": "16:00-17:30",
                            "details": `Урочисте гасіння серії поштових марок від Укрпошта, присвяченої 5-ти річчю IYFR Україна.`,
                          },
                          {
                            "time": "17:30-18:30",
                            "details": `Щорічне загальне засідання Командорів Українських Ротарійських IYFR Флотів - запрошені IYFR foreign and International Commodores and Fleet members 
                            
Passcode: 578701`,
                            site1: 'https://us02web.zoom.us/j/88505473920',  
                          },
                          {
                            "time": "18:30-19:00",
                            "details": `Вільний час, спілкування`,
                          },

                          //=============================
                          {
                            "time": "19.00-21.00",
                            "details": `Дружня вечеря. Ресторан «Ушуайя».`,
                            link: 'https://www.google.com/search?client=safari&sca_esv=ce82090d53e0c143&sca_upv=1&sxsrf=ADLYWIIProNEDuTMwlcRLgbsnUt9ZW08cA%3A1722408053127&q=USHUAIA&ludocid=4738992339709463534&ibp=gwp%3B0%2C7&lsig=AB86z5WbBZ1YsIyG_hftS0vDj7RN&kgs=f91bc5bb668d7f44&shndl=-1&shem=labmtrc%2Clsde%2Cvslcca&source=sh%2Fx%2Floc%2Fact%2Fm4%2F3'
                          },
                          {
                            time: '19.00-19.05',
                            details: `Відкриття вебінару - вступне слово. Губернатор Дистрикту Олександр Хардін (РК Полтава)`,
                          },
                          {
                            time: '19.05-19.20',
                            details: `1. Голова Комітету з міжнародної діяльності ДГЕ Ольга Палійчук (РК Черкаси). 
Співпраця з Ротарійськими Товариствами та Групами Дії за результатами Ротарі Конвенції в Сингапурі, 
новини спільної роботи - з чого починати при відкриття української секції в Д2232? 
15 хв., 19.05-19.20.`,
                            site1: 'https://www.wash-rag.org/',
                            site2: 'https://ragonmentalhealth.org/',
                            site3: 'https://www.rotary-wine.org/',
                          },
                          {
                            time: '19.20-19.30',
                            details: `2. Голова української секції Міжнародного Ротарійського Лікарського Товариства (International Rotary Fellowship Healthcare Professionals - IRFHP) 
Роман Міцода (РК Ужгород-Скала), 
10 хв., 19.20-19.30.
План підготовки ротарійських проєктів медичного спрямування від малого локального до глобальних грантів. `,
                            site1: 'https://www.rotaryhealthprofessionals.org/',
                          },
                          {
                            time: '19:30-19:40',
                            details: `3. Командор Чорноморського Яхтового Ротарійського Флоту IYFR Black Sea Ukrainian Fleet 
Андрій Черепанов (РК Миколаїв), 
10 хв., 19:30-19:40. 
Календар подій Міжнародного Яхтового Товариства (IYFR - International Yachting Fellowship of Rotarians) 
та IYFR Region Ukraine в світі та в Д2232 - виклики воєнного часу та як їх подолати разом з друзями-ротарійцями.`,
                            site1: 'https://www.iyfr.net/',
                            site2: 'https://iyfr.tyko.com.ua/',
                          },
                          {
                            time: '19:40-19:50',
                            details: `4. Голова нового Міжнародного Ротарійського Товариства Освітян (IRFE - International Rotary Fellowship of Educators) 
Ірина Одинцова (РК Дніпро-Новий), 
10 хв., 19:40-19:50. 
Як підготувати ротарійський проєкт служіння в напрямку забезпечення освітнього простору, типові приклади та методи пошуку іноземних партнерів.`,
                            site1: 'https://www.facebook.com/profile.php?id=61552207319582',
                          },
                          {
                            time: '19.50-20.00',
                            details: `5. Міжнародна Ротарійська Група Дії з миробудування (RAGFP - Rotary Action Group for Peace), 
Директор RAGFP в країнах ЄС 
Дирк Люстиг (РК Київ-Столиця), 
10 хв., 19.50-20.00. 
Створення світової мережі Peacebuilding Rotary & Rotaract Clubs - досвід України та Д2232, план спільних дій з міжнародними партнерами в реалізації проєктів по відновленню громад та з напрямку гуманітарного розмінування.`,
                            site1: 'https://rotaryactiongroupforpeace.org/',
                          },
                          {
                            time: '20.00-20.10',
                            details: `6. Презентація діяльності української секції Міжнародного Ротарійського Товариства Віскі, 
10 хв., 20.00-20.10
та виїзне засідання українського ротарійського клубу "Шанувальників віскі" (паралельно офлайн в сусідній кімнаті), 
20.00-...
Юзеф Броневский (РК Харків)`,
                            site1: 'http://www.whiskeydram.org/',
                          },
                          
                          {
                            time: '20.10-20.30',
                            details: `7. Дискусія, питання, обговорення
20 хв., 20.00-20.30`,
                          },
                          {
                            time: '20.10-21.30',
                            details: `8. Неформальна частина - продовження за бажанням живого спілкування та обговорення. `
                          }
                        ]
                      },
                      {
                        "date": "03.08.2024",
                        "events": [
                          {
                            "time": "10:00-11:00",
                            "details": "Обласний Яхт-Клуб, м. Миколаїв, вул. Спортивна 7. Показові вітрильні перегони учнів Вітрильної Школи Миколаївського Яхт-Клубу. Спілкування тренерів гостей з тренерами Вітрильної Школи, обмін досвідом."
                          },
                          {
                            "time": "11:00-12:00",
                            "details": "Огляд історичної будівлі Яхт-Клубу, фотосесія біля знаку Rotary International."
                          },
                          {
                            "time": "12:00-18:00",
                            "details": "Вільний час. Спілкування, прогулянки, відпочинок."
                          },
                          {
                            "time": "18:00-",
                            "details": "Дружня вечеря в ресторані Дача",
                            link: 'https://www.google.com/search?q=%D1%80%D0%B5%D1%81%D1%82%D0%BE%D1%80%D0%B0%D0%BD+%D0%B4%D0%B0%D1%87%D0%B0+%D0%BD%D0%B8%D0%BA%D0%BE%D0%BB%D0%B0%D0%B5%D0%B2&client=safari&sca_esv=5caaf0c1b0a489d0&sca_upv=1&sxsrf=ADLYWIKivyilf7WJj0PhDaZ8PiWY3ReK4A%3A1722409273827&source=hp&ei=OeGpZvvkL8qK9u8Pu_W0qAQ&gs_ssp=eJzj4tVP1zc0TDYsNC9ONy0zYLRSNagwMUg2TbY0NDc2NTY3NjE3tjKoME1JS01NtTA3NzU2M0q1NPbSuthwYevFxotNF_YBWRsu7FW4sOXChovtFzYoXNh7YceFXRf2XdgNFN96YRMAhIksaA&oq=%D1%80%D0%B5%D1%81%D1%82%D0%BE%D1%80%D0%B0%D0%BD+%D0%B4%D0%B0%D1%87%D0%B0%C2%A0&gs_lp=EhFtb2JpbGUtZ3dzLXdpei1ocCIb0YDQtdGB0YLQvtGA0LDQvSDQtNCw0YfQsMKgKgIIADIOEC4YgAQYxwEYywEYrwEyERAuGIAEGMcBGMsBGI4FGK8BMg4QLhiABBjHARjLARivATIIEAAYgAQYywEyERAuGIAEGMcBGMsBGI4FGK8BMggQABiABBjLATIIEAAYgAQYywEyCBAAGIAEGMsBSO1FUKMTWNcycAJ4AJABAJgBsQKgAakTqgEIMC4xMC4zLjG4AQHIAQD4AQGYAhCgAqwUqAIPwgIHECMYJxjqAsICDRAuGMcBGCcY6gIYrwHCAgcQLhgnGOoCwgIMECMYgAQYExgnGIoFwgISEC4YgAQYExjHARgnGIoFGK8BwgIFEAAYgATCAgUQLhiABMICCBAuGIAEGNQCwgILEC4YgAQYxwEYrwHCAggQLhiABBjLAcICCxAAGIAEGMkDGMsBmAMSkgcIMi4xMC4zLjGgB82IAg&sclient=mobile-gws-wiz-hp',
                            link2: 'https://savva-libkin.com/restaurant/odessa/dacha'
                        }
                        ]
                      }
                    ]               
            },
            
            ],
    },
    
    {
        id: 1,
        header: `Урочисте гасіння серії поштових марок від Укрпошта, присвяченої 5-ти річчю IYFR Україна.`,
        arrcontent: [
            {
                PhotoCarusel_15x10: [
                "https://7fc65ea596c38701.cdn.express/secure/oyFK_vWPvMPLDqcDQVJ6XQ/clubsitecreator/fs_iyfr/other_photo/0-02-01-4836f7bf08af0208251ab631d87781c79887b88a6552bea19c073340b66aaf08_79aec3ad4270fa64.jpg",    
                "https://7fc65ea596c38701.cdn.express/secure/RTL7S0TfprREloAMcABEJw/clubsitecreator/fs_iyfr/other_photo/0-02-01-11d6483e4b91a3e65e5f0a087c323f9287e6e03e2dc6d8626836cd5cf6e107e1_47f4aceba1af63a.jpg",    
                ]
            },
            {
                PhotoCarusel_15x10: [    
                "https://7fc65ea596c38701.cdn.express/secure/486D5oDv-NiMBYr9OUQ08g/clubsitecreator/fs_iyfr/other_photo/0-02-01-4650474a5e2ca227232dc996c6b1c3abe85832e08f6925edc5c7d96c77c6b95c_84910aad23e3eb8f.jpg",    
                "https://7fc65ea596c38701.cdn.express/secure/jSE5BjWqeD4mdCJ2mMOygA/clubsitecreator/fs_iyfr/other_photo/0-02-05-aa1a66aed9b6373e095fca7782f18aedf3ce76f1d4eb37b7a0e30d52df988ce4_30584599f1d3c4b7.jpg",    
            ],
            }
        ]
    },
    
    {
        id: 1,
        header: `Українська секція Міжнародного Ротарійського Товариства Віскі.
Засідання українського ротарійського клубу "Шанувальників віскі"`,
        arrcontent: [
            {
                PhotoCarusel_15x10: [
            "https://7fc65ea596c38701.cdn.express/secure/QLKLHwKMgGSnvVr6br00cQ/clubsitecreator/fs_iyfr/photo2/0-02-05-7c50b6962ac06d2428a2f61bbe84621380c90a2da05c850629a9aa8febcc2404_92985b13c5150f7d.jpg",    
            "https://7fc65ea596c38701.cdn.express/secure/6Ydon5kE5OF2-4VeY6MLpQ/clubsitecreator/fs_iyfr/photo2/0-02-05-40f37921d5985498ce28782f1056a817ebb0024c0d8b04cbf78c6b5a9b52acfa_4200fc1f4256829.jpg",    
            "https://7fc65ea596c38701.cdn.express/secure/bZlMIej9AU-nHcucX7wCcA/clubsitecreator/fs_iyfr/photo2/0-02-05-a1cc95cca1043f2d64f3b9d5cd238724cd53291d26582be845e61d16e364a381_f304b24927a0a969(1).jpg",     
            "https://7fc65ea596c38701.cdn.express/secure/ril3Hgu2TbSyInOudALVeQ/clubsitecreator/fs_iyfr/photo2/0-02-05-e229af2bc55e0e946b48a74ad6279d4abf02fbc8367ba91ce651140325cf3364_83574e4ccf9313b5(1).jpg",    
                ],
            },
        ],
    },

    {
        id: 0,
        header: 'Фото Галерея:',
        arrcontent: [
            {
                PhotoCarusel_15x10: [
                    "https://7fc65ea596c38701.cdn.express/secure/j8f4es5zsFlXINrt1LQZFA/clubsitecreator/fs_iyfr/other_photo/0-02-01-0a5ffb6a108d705b13d0336eb2af6373eeec5108482c9db23f082086e3a409ce_9949adf518998b0c.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/RTL7S0TfprREloAMcABEJw/clubsitecreator/fs_iyfr/other_photo/0-02-01-11d6483e4b91a3e65e5f0a087c323f9287e6e03e2dc6d8626836cd5cf6e107e1_47f4aceba1af63a.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/rJC5j9O47LW86-SEruskqw/clubsitecreator/fs_iyfr/other_photo/0-02-01-17167b9dda375719bf179cf84ec04e7ea0b99e0f22ade4b616e86a93f4e543e5_6d0c8aeab96d3e9c.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/17KXIN9kDcsnp53tcLG1Gw/clubsitecreator/fs_iyfr/other_photo/0-02-01-35289c265ce1fe3629c43d9aec8d02e6fe97dc4ef2929d84d166ce918474a377_98d254dd03727e8c.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/486D5oDv-NiMBYr9OUQ08g/clubsitecreator/fs_iyfr/other_photo/0-02-01-4650474a5e2ca227232dc996c6b1c3abe85832e08f6925edc5c7d96c77c6b95c_84910aad23e3eb8f.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/oyFK_vWPvMPLDqcDQVJ6XQ/clubsitecreator/fs_iyfr/other_photo/0-02-01-4836f7bf08af0208251ab631d87781c79887b88a6552bea19c073340b66aaf08_79aec3ad4270fa64.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/y3-ga1t2zaxv4towDaQ2qA/clubsitecreator/fs_iyfr/other_photo/0-02-01-7e467625353b2f752569d7d379846eac37b5136b69b9c400cc468758e9406e73_86486828f0c64aeb.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/U3M1k9IdCe24_3PzcMT6Jg/clubsitecreator/fs_iyfr/other_photo/0-02-01-9eda3ea036544186300819efa82f6a030884b4701b85c0058f1670a5f97e7a36_f657f6cb9481ee94.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/gH8a8Pdp0JHJ-YYPuPGuZg/clubsitecreator/fs_iyfr/other_photo/0-02-01-a008c7c09cfa61e42a3b8c6b6a90e58d0000836c70f0bb1e510174e7cf0f184e_459c2b0f003bc0d8.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/RK0EwmdX8echcbORexuKiA/clubsitecreator/fs_iyfr/other_photo/0-02-01-a32060586a451224154181a9019f2a3fd53683856ee6f6e5b5a14066404217cd_f820515e5f56be44.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/9d-DRzV-jV3cQ3KDv6HXbg/clubsitecreator/fs_iyfr/other_photo/0-02-01-b12c9a96022ae5a66e8ba152ce110f6d221c3cb34f5cd096504ed9e0d85a3ce7_2195c43527d95392.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/INr6x3BIYjC5guqMZ8celQ/clubsitecreator/fs_iyfr/other_photo/0-02-01-d317d2fed2ba9c125f13f7f36a3aa6cdaaabb298f1542f479ff8d30d78fe3321_336d3738631d3cad.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/YoBoWe6VHm8isRKAn-8dhQ/clubsitecreator/fs_iyfr/other_photo/0-02-01-dda5ec1daa5b07e8c32b8973eb0eb2f1de9d65259eb961dc36a9bc82e57686cf_e754d2a2128f1c70.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/hEB8p1oHgCXWbXPGhgnCIg/clubsitecreator/fs_iyfr/other_photo/0-02-01-ebbb18272857ae9b33914becc9858d7f7af18505ba968ce2941ab9ca572107de_e0d644ef24a02dc1.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/CBiV54hNi_lz-CpCCtcAng/clubsitecreator/fs_iyfr/other_photo/0-02-01-ede74d11224f4ee58cd94be47ea8caa613dc7c0355fb06f8351fbe8dbd067505_17bfe968a524c7c6.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/a9iCv42RXv87KppsOL3Zsw/clubsitecreator/fs_iyfr/other_photo/0-02-05-954ebee8074d658188c6992f622c7a6d415041b5ec623a899f51093a21e45aad_f001f8ee26094f11.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/VhwJmyqnV4MUY_RT3hsTSQ/clubsitecreator/fs_iyfr/other_photo/0-02-05-99f84be33dd738b780acf3543e0f2b7b98de0670af41faf4dcc3190a20291e0f_4a4d5e81f6b44831.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/ccypJxlzvnFsxXN01gNumg/clubsitecreator/fs_iyfr/other_photo/0-02-05-e822ba859c3765cf88ec9a8f4347604c2dbf474580165626ac865e8dd109ec15_210da6e3edb4350a.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/6Ydon5kE5OF2-4VeY6MLpQ/clubsitecreator/fs_iyfr/photo2/0-02-05-40f37921d5985498ce28782f1056a817ebb0024c0d8b04cbf78c6b5a9b52acfa_4200fc1f4256829.jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/bZlMIej9AU-nHcucX7wCcA/clubsitecreator/fs_iyfr/photo2/0-02-05-a1cc95cca1043f2d64f3b9d5cd238724cd53291d26582be845e61d16e364a381_f304b24927a0a969(1).jpg",
                    "https://7fc65ea596c38701.cdn.express/secure/ril3Hgu2TbSyInOudALVeQ/clubsitecreator/fs_iyfr/photo2/0-02-05-e229af2bc55e0e946b48a74ad6279d4abf02fbc8367ba91ce651140325cf3364_83574e4ccf9313b5(1).jpg",
                ],
            },
        ],
    },
    
    
    {
        id: 1,
        header: `Важливі файли:`,
        content: [
            {LandingLink: "Посилання на журнал", link: "https://7fc65ea596c38701.cdn.express/secure/v2VRS_ZVf-SB4AW9bnFcPA/clubsitecreator/fs_iyfr/documents/Rotafloat 118.pdf"},
            {BR: "true"},
            {LandingLink: "Презентація з Сингапура та ЄС з AGM", link: "https://7fc65ea596c38701.cdn.express/secure/P2RZzzrGABIQs3r65k6BpQ/clubsitecreator/fs_iyfr/documents/IYFR Region Ukraine Belgrad 08, June, 2024 - Olha Paliychuk.pdf"},
        ],
    },
    {
        id: 1,
        header: `Рекомендовані готелі до поселення в Миколаєві:`,
        content: [
            {LandingImage: "true", fullsize: true, link: "https://7fc65ea596c38701.cdn.express/secure/UtABXB0oFVoDmEM_8TEmmg/clubsitecreator/fs_iyfr/photo2/hotel1.jpg"},
            {LandingImage: "true", fullsize: true, link: "https://7fc65ea596c38701.cdn.express/secure/QAVLFub25RCq4ac2laRxAA/clubsitecreator/fs_iyfr/photo2/hotel2.jpg"},
            {LandingImage: "true", fullsize: true, link: "https://7fc65ea596c38701.cdn.express/secure/MLJhR3tTzPagUbdxVWks7A/clubsitecreator/fs_iyfr/photo2/hotel3.jpg"},
         ],
    },
    {
        id: 1,
        _header: ``,
        content: [
            {LandingImage: "true", link: "https://7fc65ea596c38701.cdn.express/secure/fdh7LCtjXTxiG5T7GGDFWg/clubsitecreator/fs_iyfr/photo2/Kosmyna.jpg"},
            {LandingMessage: `Host Master IYFR Region Ukraine 
Голова Комітету ІТ Д2232
Олег Косьмина
РК Львів-Ратуша
Cherkasy Ukrainian IYFR Fleet

Більше інформації про діяльність Українських Ротарійських Яхтових Флотів на `},
            {LandingLink: "сайті IYFR Region Ukraine", link: "https://iyfr.tyko.com.ua/region"},
        
        ],
    },

];
