import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const DndArea = ({handleDndFilesChange}) => {
  const [files, setFiles] = useState([]);

  const onDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
    handleDndFilesChange([...files, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Container maxWidth="sm">
      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed #3f51b5',
          borderRadius: 2,
          padding: 1,
          textAlign: 'center',
          cursor: 'pointer',
          bgcolor: '#f9f9f9',
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="body1" noWrap>
          Перетягніть файли сюди, або натисніть, щоб вибрати файли
        </Typography>
      </Box>
      {/* <List sx={{ marginTop: 2 }}>
        {files.map((file, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <ListItemText primary={file.name} secondary={`${file.size} bytes`} />
          </ListItem>
        ))}
      </List> */}
    </Container>
  );
};

export default DndArea;
