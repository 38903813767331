import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Avatar, Grid, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Button } from '@mui/material';
import QRGenerator from './QRGenerator';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import successSound from '../../assets/audio/fanfara.mp3'; 
import { getAllUsers } from '../../api_sequelize/api_eventusers';

const logo = "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/LogoD2232_withborders.png";
const rylaLogo = 'https://api.rotary.org.ua/uploads/clubsitecreator/SharedImages/RYLA_Logo_large_v3.png';

const RylaCard = () => {
    const [isRegistered, setIsRegistered] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const audioRef = useRef(null);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const userid = urlParams.get('userid');
    const data = `https://rotaryd2232ukraine.org/qrgen?userid=${userid}`;

    const [users, setUsers] = useState([]);
    const [foundedUser, setFoundedUser] = useState(null);

    useEffect(() => {
        const checkRegistration = async () => {
            try {
                const response = await getAllUsers();
                console.log(`getAllUsers length=${response && response.data && response.data.length}`);
                setUsers(response.data);
                const foundUser = response.data.find(user => String(user.user_id) === String(userid)) || null;

                if (foundUser) {
                    setFoundedUser(foundUser);
                    setIsRegistered(true);
                    console.log(`User found: ${JSON.stringify(foundUser)}`);

                    if (foundUser.tags==='yes') {
                        console.log(`User is registered: ${JSON.stringify(foundUser)}`);
                        return true; 
                    } else {
                        console.log(`User isn't registered:: ${JSON.stringify(foundUser)}`);
                        return false;
                    }
                        
                } else {
                    console.log('User not found');
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
            return false; // Return false if the user is not found or an error occurred
        };

        const initCheck = async () => {
            const isUserRegistered = await checkRegistration();
            if (!isUserRegistered) {
                const intervalId = setInterval(async () => {
                    console.log('Checking registration again...');
                    const isUserRegistered = await checkRegistration();
                    if (isUserRegistered) {
                        setDialogOpen(true);
                        clearInterval(intervalId);
                        console.log('User registered during interval check');
                    } else {
                        console.log('User still not registered');
                    }
                }, 10000); // 10 seconds
                return () => clearInterval(intervalId);
            } else {
                console.log('User already registered on initial check');
            }
        };

        initCheck();
    }, []);

    const handleClose = () => {
        setDialogOpen(false);
        audioRef.current.play(); // Play the success sound after closing the dialog
    };

    return (
        <>
        {foundedUser ?
        <Card sx={{ maxWidth: 400, mx: 'auto', mt: 5, p: 2, boxShadow: 3 }}>
        <CardContent>
            <Typography
            variant="h5"
            component="div"
            textAlign="center"
            gutterBottom
            sx={{ fontWeight: 'bold', color: '#0D47A1' }}
            >
            Візитівка учасника RYLA
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
            <img src={rylaLogo} alt="RYLA Logo" style={{ width: '40%', marginRight: '16px' }} />
            {foundedUser && <Avatar src={foundedUser.avatar_url} alt="Participant Photo" sx={{ width: 150, height: 150 }} />}
            </Box>
            <Typography
            variant="h3"
            component="div"
            textAlign="center"
            gutterBottom
            sx={{ fontWeight: 'bold', color: 'orange' }}
            >
            {foundedUser.first_name} {foundedUser.last_name}
            </Typography>

            
            {!(foundedUser && foundedUser.tags==='yes') &&
            <>
            <Typography
            variant="h5"
            component="div"
            textAlign="center"
            gutterBottom
            sx={{ color: '#0D47A1' }}
            >
            RYLA Registration Qr-code
            </Typography>
            <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} md={6} textAlign="center">
                <QRGenerator data={data} logo={logo} width={400} height={400} />
            </Grid>
            </Grid>
            </>
            }

            <Box sx={{ mt: 2, pl: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                <Typography>Онлайн реєстрація на ячасть в події - зарестровано.</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {foundedUser && foundedUser.tags==='yes' ? (
                <>
                    <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                    <Typography>Офлайн реєстрація на рецепшн - зареєстровано.</Typography>
                </>
                ) : (
                <>
                    <CancelIcon sx={{ color: 'red', mr: 1 }} />
                    <Typography>Офлайн реєстрація на рецепшн - не зареєстровано.</Typography>
                </>
                )}
            </Box>
            </Box>
        </CardContent>

        <Dialog open={dialogOpen} onClose={handleClose}>
            <DialogTitle>Реєстрація</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Ви успішно зарестровані.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">OK</Button>
            </DialogActions>
        </Dialog>

        <audio ref={audioRef} src={successSound} />
        </Card>
        : <>
            Відвідувача з id:{userid} не знайдено<br/>
            foundUser={JSON.stringify(foundedUser)}<br/>
            users={users && users.length}
            </>
        }
        </>
    );
};

export default RylaCard;
