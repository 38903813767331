
import { alpha } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';

const colorMap = {
    '1': '#0000FF', // blue
    '2': '#008000', // green
    '3': '#FFA500', // orange
    'default': '#808080' // grey
};

export const getEventColor = (colorId, pastEvent) => {
    const color = colorMap[colorId] || colorMap['default'];

    // Якщо подія в минулому, робимо колір напівпрозорим
    return pastEvent ? alpha(color, 0.3) : alpha(color, 0.7);
};

const getAvatarLetter = (colorId) => {
    switch (colorId) {
        case '1':
            return 'Д';
        case '2':
            return 'К';
        case '3':
            return 'Г';
        default:
            return 'Е';
    }
};

const BlueAvatar = ({ colorId, pastEvent = false }) => {
    const color = getEventColor(colorId, pastEvent);
    const text = getAvatarLetter(colorId);

    return (
        <Avatar
            sx={{
                bgcolor: color,
                color: 'white', // колір тексту
                fontWeight: 'bold', // жирний шрифт для букви
                mr: '24px',
            }}
        >
            {text}
        </Avatar>
    );
};

export default BlueAvatar;