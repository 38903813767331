import React from "react";

import LandingArrContentBlock from '../../../components/LandingArrContentBlock';
import LandingContentBlock from '../../../components/LandingContentBlock';
import LandingContentBlock2 from '../fixcomponents/LandingContentBlock2';

import LandingPdfViewer from '../../../components/LandingPdfViewer';

const LandingContent = ({data, designType}) => {
    return (
        <>
        {data && data.map((item,index) => (
            <>
            {(item.content || item.img || item.cards || item.cards2) && <LandingContentBlock id={index} landItem={item} designType={designType} designmode={item.designmode} />}
            {item.arrcontent && <LandingArrContentBlock id={index} arrItem={item} designType={designType} designmode={item.designmode} />}
            {(item.content || item.img || item.imgs2 
                || item.cards3 
                || item.LandingProjects || item.LandingProjects2 || item.LandingProjects3_eclub || item.LandingProjects2_Singapore 
                || item.LandingCardEvent 
                || item.LandingChartProjects || item.LandingBdRotarians 
                || item.LandingBdRotariansPublic || item.LandingCardsRotarians || item.LandingCardsRotarians_eclub || item.LandingEventFrame 
                || item.LandingStatisticsBlock || item.LandingAreaOfFocusBlock || item.LandingAreaOfClubFocusBlock 
                || item.VideoHeaderForLanding ) 
                && <LandingContentBlock2 id={index} landItem={item} designType={designType} designmode={item.designmode} />
            }
            {item.content && item.content.map((contItem, index) => (
                <React.Fragment key={index}>
                {contItem.LandingPdfViewer && <LandingPdfViewer fileUrl={contItem.LandingPdfViewer} size={contItem.size} />}
                </React.Fragment>
            ))
            }
            </>
        ))}
        </>
    )
}

export default LandingContent;