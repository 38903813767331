import React, { useState } from 'react';
import { apiText2Audio, apiText2Audio_google } from '../../api_tools/apiAudio2Text';
import {
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
  Box
} from '@mui/material';

//const SERVERURL = 'https://apitools.rotaryd2232ukraine.org';
const SERVERURL = 'http://localhost:3001';

const AiText2Audio = ({isGoogle}) => {
  const [text, setText] = useState('');
  const [filename, setFilename] = useState('');
  const [voice, setVoice] = useState('fable');
  const [speed, setSpeed] = useState(1.0);
  const [pitch, setPitch] = useState(1.0);
  const [message, setMessage] = useState('');
  const [audioPath, setAudioPath] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setAudioPath('');
    setLoading(true);

    try {
        const response = await 
            //isGoogle==="true"
            //? 
            apiText2Audio_google(text, filename, voice, speed, pitch)
            //: apiText2Audio(text, filename, voice, speed, pitch)
        setMessage(response.data.message);
        setAudioPath(SERVERURL + response.data.path);
        console.log(`handleSubmit mpath=${SERVERURL + response.data.path}`);
    } catch (error) {
      setMessage('Error creating audio file');
      console.error('Error creating audio file:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Text to Audio Converter
        </Typography>
        isGoogle={isGoogle}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Text"
            multiline
            rows={8}
            variant="outlined"
            fullWidth
            margin="normal"
            value={text}
            onChange={(e) => setText(e.target.value)}
            required
          />
          <TextField
            label="Filename"
            variant="outlined"
            fullWidth
            margin="normal"
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
            required
          />
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel>Voice</InputLabel>
            <Select
              value={voice}
              onChange={(e) => setVoice(e.target.value)}
              label="Voice"
            >
              <MenuItem value="nova">Nova</MenuItem>
              <MenuItem value="shimmer">Shimmer</MenuItem>
              <MenuItem value="echo">Echo</MenuItem>
              <MenuItem value="onyx">Onyx</MenuItem>
              <MenuItem value="fable">Fable</MenuItem>
              <MenuItem value="alloy">Alloy</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Speed"
            type="number"
            step="0.1"
            variant="outlined"
            fullWidth
            margin="normal"
            value={speed}
            onChange={(e) => setSpeed(e.target.value)}
          />
          <TextField
            label="Pitch"
            type="number"
            step="0.1"
            variant="outlined"
            fullWidth
            margin="normal"
            value={pitch}
            onChange={(e) => setPitch(e.target.value)}
          />
          <Box mt={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              fullWidth
            >
              Convert
            </Button>
          </Box>
        </form>
        {loading && (
          <Box mt={2} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {message && (
          <Typography variant="body1" color="error" mt={2}>
            {message}
          </Typography>
        )}
        {audioPath && (
          <Box mt={2}>
            <audio controls>
              <source src={audioPath} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default AiText2Audio;
