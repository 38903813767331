import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import CrudTable from './CrudTable';
import AdminDialogCrud from './AdminDialogCrud';

const generateDefaultObject = (columns) => {
    return columns.reduce((acc, column) => {
        acc[column.field] = column.default;
        return acc;
    }, {});
};

const CRUD_DbAdmin = ({ data: initialData, tableTitles, crudFunctions, loadParams, refreshData, fRowClicked }) => {
    const [fetchedData, setFetchedData] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState(generateDefaultObject(tableTitles));
    const [rowClicked, setRowClicked] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
        loadData();
    }, [loadParams]);

    const loadData = async () => {
        try {
            const fetched = await crudFunctions.loadItems(loadParams);
            setFetchedData(fetched);
            refreshData(fetched);
        } catch (error) {
            console.error(error);
        }
    }

    const handleEditUser = (defaultData = generateDefaultObject(tableTitles)) => {
        setCurrentRow(defaultData);
        setIsEditMode(!!defaultData.id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        console.log('=== handleSubmit...');
        const currentRow_new = { ...currentRow };

        try {
            if (isEditMode) {
                await crudFunctions.updateItem(currentRow_new.id, currentRow_new);
            } else {
                await crudFunctions.createItem(currentRow_new);
            }
            handleClose();
            loadData();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async (id) => {
        console.log('=== handleDelete... Id=' + id);
        const confirmDelete = window.confirm('Чи дійсно хочете видалити картку ротарійця?');
        if (!confirmDelete) return;

        try {
            await crudFunctions.deleteItem(id);
            loadData();
        } catch (error) {
            console.error(error);
        }
    };

    const onRowClicked = (row) => {
        setRowClicked(row);
        fRowClicked(row);
    };

    return (
        <div>
            <Box
                display="flex"
                flexDirection="row"
                pt="8px"
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    backgroundColor: '#fff',
                    borderBottom: '1px solid #ddd',
                    padding: '8px',
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEditUser()}
                    sx={{
                        whiteSpace: 'nowrap', // Забороняє перенесення тексту
                    }}
                >
                    Add Item
                </Button>

                <Box width="100%"></Box>

                {/* {rowClicked && (
                    <Button variant="contained" color="primary" onClick={() => handleEditUserSkills()}>
                        Edit Skills
                    </Button>
                )} */}
            </Box>

            <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}>
                <CrudTable
                    data={fetchedData}
                    columns={tableTitles}
                    onEdit={handleEditUser}
                    onDelete={handleDelete}
                    onRowClicked={onRowClicked}
                />

                <AdminDialogCrud
                    open={open}
                    onClose={handleClose}
                    onSubmit={handleSubmit}
                    isEditMode={isEditMode}
                    columns={tableTitles}
                    currentItem={currentRow}
                    setCurrentItem={setCurrentRow}
                />

            </Box>
        </div>
    );
};

export default CRUD_DbAdmin;
