import { useEffect, useState, useContext, useRef } from "react";
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import TykoLanding from '../../components/TykoLanding';
import {LocalContext} from '../../../../tools/LanguageContextProvider';
import LandingContent from '../../components/LandingContent';

const RcClubPageMain = ({
        designType, 
        menufunc,
        pagesContent, 
        tags
    }) => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [navbar, setNavbar] = useState(null);
    const [footer, setFooter] = useState(null);
    const [data_home, setData] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
          try {
              await loadData(currentLocale);
          } catch (error) {
              console.log("DanatePage get data list error="+error);
          }
        };
        fetchData();
    }, [])

    useEffect(() => {
        console.log("Change curent langufge то ="+currentLocale);

        loadData(currentLocale);
    }, [currentLocale])

    const loadData = async(locale) => {
        const page = menufunc(locale);
        setNavbar(page.navbar);
        setFooter(page.footer);

        const d = await pagesContent(locale);
        setData( d );

        console.log("DanatePage get data list success. const="+page.length);
    }

    return (
        <>
        {/* urlParams = {JSON.stringify(urlParams )}
        p1={p1}<br/>
        p2={p2} */}
        
            <TykoLanding 
                navbar={navbar} 
                designType={designType} 
            >
            {(() => {
                switch (id) {
                    case 'home':
                    case '':
                        return (
                            <LandingContent data={data_home} currentLocale={currentLocale} designType={designType} />
                        );

                    case 'program':
                        return (
                            <LandingContent data={data_home} currentLocale={currentLocale} designType={designType} />
                        );
        
                    default:
                        return data_home ? (
                            <LandingContent data={data_home} currentLocale={currentLocale} designType={designType} />
                        ) : (
                            "Loading..."
                        );
                }
            })()}
            </TykoLanding>
        </>
    )
};

export default RcClubPageMain;