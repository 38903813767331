import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAllSites, getSiteById, createSite, updateSite, deleteSite } from '../../api_sequelize/api_sites';
import { Container, Button } from '@mui/material';
import { Paper } from '@mui/material';
import CRUD_DbAdmin from '../RC_Cherkasy/rotariansBD/adminsPages/CRUD_DbAdmin';

const tableTitles = [
    { field: 'id',      headerName: 'id', type: 'lineedit' },
    { field: 'suburl',      headerName: 'suburl', type: 'lineedit' },
    { field: 'title',  headerName: 'title', type: 'lineedit' },
    { field: 'desc',  headerName: 'desc', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'designtype', headerName: 'designtype', type: 'lineedit', default: '' },
    { field: 'logourl',  headerName: 'logourl', type: 'lineedit', default: '', tablehided: 'true' },
    { field: 'languages',    headerName: 'languages:', type: 'lineedit', default: '', tablehided: 'true' },
];

const crud_functions = {
    loadItems: getAllSites,
    createItem: createSite,
    updateItem: updateSite,
    deleteItem: deleteSite,
}

const AdminSites = () => {
    const [sites, setSites] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const refreshData = async (data) => {
        setSites(data);
    };
    const fRowClicked = (row) => {
        const searchParams = new URLSearchParams(location.search);

        const currId = sites && sites[row] && sites[row].id;
        searchParams.set('id', currId);
        navigate(`${location.pathname}?${searchParams.toString()}`);
    }

    return (
        <Container>
        <Paper>
            Admin Sites
            
            <CRUD_DbAdmin
                data={sites}
                tableTitles={tableTitles}
                crudFunctions={crud_functions}
                refreshData={refreshData}
                fRowClicked={fRowClicked}
            />
        </Paper>
        </Container>
    );
};

export default AdminSites;
