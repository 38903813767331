export const getMenuStructure = (local) => {
    return data_eng;
}

const data_eng = {

    navbar: {
        _title: "Annual General Meeting",
        logoUrl: 'https://7fc65ea596c38701.cdn.express/secure/8wBcofrvV2ygDCGb60Kx1A/clubsitecreator/fs_iyfr/logo/IYFR_logo.png',
        // languages: [
        //     "en-US",
        //     "ua-UA",
        // ],
        // autorization: "true",
        addlogoborder: true,
        withoutd2232url: true, 
        menu: [
            {
                title: "Подія",
                lref: '/fs_iyfr/home',
            },
            {
                title: "На сайт IYFR",
                lref: 'https://iyfr.tyko.com.ua/',
            },
        ],
    },
    footer: {
        contactPerson_: {
            name: "Priamry contacts:",
            email: "rotaryeclubua@gmail.com",
        },
    }
}
