import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getAllSitePages, getSitePagesBySiteId, createSitePage, updateSitePage, deleteSitePage } from '../../api_sequelize/api_sites';
import { Container, Button } from '@mui/material';
import { Paper } from '@mui/material';
import CRUD_DbAdmin from '../RC_Cherkasy/rotariansBD/adminsPages/CRUD_DbAdmin';

const getSitePagesWithParaams = async (params) => {
    console.log("===== getSiteMunusWithParaams params="+JSON.stringify(params));
    const menuId = params && params.menuId.menuId;
    return await getSitePagesBySiteId(menuId)
}

const crud_functions = {
    loadItems: getAllSitePages,
    createItem: createSitePage,
    updateItem: updateSitePage,
    deleteItem: deleteSitePage,
}

const AdminSitePages = () => {
    const [sites, setSites] = useState(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const menuId = searchParams.get('menuid');
    const loadParams = { menuId: {menuId} };

    const tableTitles = [
        { field: 'id',          headerName: 'id', type: 'lineedit' },
        { field: 'menuId',      headerName: 'menuId', type: 'lineedit', default: menuId, tablehided: 'true' },
        { field: 'url_path',    headerName: 'url_path', type: 'lineedit', default: '' },
        { field: 'button_title',headerName: 'button_title', type: 'lineedit', default: '', tablehided: 'true' },
        { field: 'lang',        headerName: 'lang', type: 'lineedit', default: '' },
        { field: 'page_json',   headerName: 'page_json:', type: 'json', default: '', tablehided: 'true' },
    ];

    const refreshData = async (data) => {
        setSites(data);
    };

    return (
        <Container>
        <Paper>
            AdminPages<br/>
            Меню сайту з menuid={menuId}
            
            <CRUD_DbAdmin
                data={sites}
                tableTitles={tableTitles}
                crudFunctions={crud_functions}
                loadParams={loadParams}
                refreshData= {refreshData}
            />
        </Paper>
        </Container>
    );
};

export default AdminSitePages;
