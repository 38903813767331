
import axios from 'axios';
const API_BASE_URL = 'https://api-sequelize.rotaryd2232ukraine.org/db';

export const apiGetEvents = async () => {
    try {
        const response = await axios.get(API_BASE_URL + '/gevents');
        return response.data;
    } catch (error) {
        console.error('Error fetching events:', error);
        throw error;
    }
}

export const createEvent = async (eventData) => {
    try {
        const response = await axios.post(API_BASE_URL + '/addevent', eventData);
        return response.data;
    } catch (error) {
        console.error('Error creating event:', error);
        throw error;
    }
}

export const updateEvent = async (id, eventData) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/updateevent/${id}`, eventData);
        return response.data;
    } catch (error) {
        console.error('Error updating event:', error);
        throw error;
    }
}

export const deleteEvent = async (id) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/deleteevent/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting event:', error);
        throw error;
    }
}