import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography, Tabs, Tab, Box, Button
} from '@mui/material';

import { getAllClubs, createClub, updateClub, deleteClub } from '../../api_sequelize/api_rclubs';
import ClubsTableComp from './ClubsTableComp';
import csvData from './clubs_data.csv'; // Це імпортує CSV файл як текст


const show_columns = 
    {
      clubID: 'show',
      clubname: 'show',
      clubcat: 'show',
      clublang: 'show',
      t_clubname: 'show',
      t_riClubId: 'show',
      clubcity: 'show',
      t_memberCount: 'show',
      t_charterDate: 'show',
      sync_res: 'show',
    };
      
// Функція для читання та парсингу CSV файлу
const fetchCsvData = async () => {
    const response = await fetch(csvData);
    const text = await response.text();
    console.log(`fetchCsvData text=` + text);
    return parseCsvToJsonWithClubType(text);
};

function parseCsvToJsonWithClubType(csv) {
    const lines = csv.split('\n').map(line => line.trim()).filter(line => line.length > 0);

    let clubs = [];
    let headers = [];

    lines.forEach((line, index) => {
        const values = line.split(';').map(val => val.trim());
        if (index === 0) {
        headers = values.map(header => header.replace(/\s+/g, '_'));
        headers.unshift('Club_type', 'D_ID');
        } else {
        let club = {};
        let clubNameAndId;

        if (values[1]) {
            clubNameAndId = values[1].match(/(.+?)\s\((\d+)\)(\*)?/);
        }

        if (clubNameAndId) {
            club['club_name'] = clubNameAndId[1];
            club['D_ID'] = clubNameAndId[2];
            club['Club_type'] = clubNameAndId[3] ? 'rotaract' : 'rotary';
        } else {
            club['club_name'] = values[1] || '';
            club['D_ID'] = null;
            club['Club_type'] = 'rotary';
        }

        for (let i = 1; i < headers.length - 2; i++) {
            club[headers[i + 2]] = values[i] || null;
        }
        clubs.push(club);
        }
    });

    return clubs;
}
      
      
const syncClubs = (importClubs, dbclubs) => {
    let newClubs = [];
  
    dbclubs.forEach((dbClub, dbIndex) => {
      const match = importClubs.find(importClub => {
        const idMatch = importClub.D_ID === dbClub.t_riClubId;
        return idMatch;
      });
  
      if (!match) {
        newClubs.push({
          ...dbClub,
          sync_res: "removed"
        });
      }
    });
  
    importClubs.forEach(importClub => {
      const match = dbclubs.some(dbClub => 
        dbClub.clubname === importClub.club_name &&
        dbClub.t_riClubId === importClub.D_ID
      );
  
      if (!match) {
        newClubs.push({
          clubname: importClub.club_name,
          clubdesc: null,
          clubcontent: null,
          clubemail: null,
          clubfacebook: null,
          clubpresident: null,
          clubpphone: null,
          clubplace: null,
          clubcat: importClub.Club_type,
          clubcity: null,
          clubimg: null,
          clubexe: null,
          clublang: null,
          t_clubId: null,
          t_clubname: null,
          t_riClubId: importClub.D_ID,
          t_memberCount: importClub.Current_Member_Count,
          t_charterDate: importClub.Charter_Date,
          sync_res: "new"
        });
      }
    });
  
    return newClubs;
};
      
const ClubsTable = () => {
    const [importClubs, setImportClubs] = useState([]);
    const [dbclubs, setDbClubs] = useState([]);
    const [newClubs, setNewClubs] = useState([]);
    const [value, setValue] = useState(0);
  
    useEffect(() => {
        LoadData();
    }, []);
  
    const LoadData = async () => {

        try {
            const resClubs = await fetchCsvData();
            const activeClubs = resClubs.filter(club => club.Is_Active === "X");
            setImportClubs(activeClubs);

            const dbcl = await getAllClubs();
            const f2 = dbcl.filter(club => club.clubcat !== "interact");
            const f3 = f2.filter(club => !club.clubname.startsWith("Satellite "));
            const f4 = f3.filter(club => !club.clubname.startsWith("Сателітний "));
            const f1 = f4.filter(club => club.clublang !== "UKR");
            setDbClubs(f1);
        
            const newClubs_ = syncClubs(activeClubs, f4);
            const newClubs_2 = newClubs_.filter(club => club.clublang !== "UKR");
            setNewClubs(newClubs_2);

            // Групуємо міста за нижнім регістром
            const cityGroups = f1.reduce((acc, club) => {
                const cityLowerCase = club.clubcity?.toLowerCase() || 'unknown';
                if (!acc[cityLowerCase]) {
                    acc[cityLowerCase] = [];
                }
                acc[cityLowerCase].push(club);
                return acc;
            }, {});

            console.log('Згруповані міста:', cityGroups);

        } catch (error) {
            console.error('Помилка при завантаженні даних:', error);
        }
    };
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleAddClick = async (club) => {
        try {

            const new_club = {
                clubname: club.club_name,
                clubcat: club.Club_type,
                clubcity: 'empty',
                clublang: 'Eng',
                t_riClubId: club.D_ID,
                t_memberCount: club.Current_Member_Count,
                t_charterDate: club.Charter_Date,
            }

            console.log('Додавання клубу:', new_club);
            const addedClub = await createClub(new_club);
            console.log('Клуб успішно доданий:', addedClub);

            LoadData();
        } catch (error) {
            console.error('Помилка при додаванні клубу:', error);
        }
    };
    
    const handleRemoveClick = async (club) => {
        try {
            console.log('Вилучення клубу:', club);

            const addedClub = await deleteClub(club.clubID);
            console.log('Клуб успішно вилучений:', addedClub);

            LoadData();
        } catch (error) {
            console.error('Помилка при вилученні клубу:', error);
        }
    };
  
    return (
      <Paper>
        <Typography variant="h6" component="div" sx={{ padding: 2 }}>
          Rotary Clubs
        </Typography>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="New Clubs" />
          <Tab label="Import Clubs" />
          <Tab label="Current Clubs" />
        </Tabs>
        <Box sx={{ padding: 2 }}>
          {value === 0 && <>
            <ClubsTableComp title="new clubs" dbclubs_={newClubs} show_columns={show_columns} handleAddClick={handleAddClick} handleRemoveClick={handleRemoveClick} />
            <ClubsTableComp title="curr clubs" dbclubs_={dbclubs} show_columns={show_columns}/>
          </>}
          {value === 1 && <ClubsTableComp title="import clubs" dbclubs_={importClubs} />}
          {value === 2 && <ClubsTableComp title="curr clubs" dbclubs_={dbclubs} />}
        </Box>
      </Paper>
    );
};
      
export default ClubsTable;
