import { useEffect, useState, useContext } from "react";
import { useParams, useLocation } from 'react-router-dom';

import TykoLanding from '../components/TykoLanding';
import { LocalContext } from '../../../tools/LanguageContextProvider';
import LandingContent from '../components/LandingContent';
import { getSitePagesByUrl } from '../../../api_sequelize/api_sites';

const RcClubPageMain_BD = ({ designType, menudata, tags }) => {
    const { currentLocale } = useContext(LocalContext);
    const [navbar, setNavbar] = useState(null);
    const [footer, setFooter] = useState(null);
    const [data1, setData1] = useState([]);
    const [dataPages, setDataPages] = useState({});
    const { id } = useParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const p1 = urlParams.get('filter');
    const p2 = urlParams.get('carea');

    useEffect(() => {
        const fetchData = async () => {
            try {
                await loadData(currentLocale);
            } catch (error) {
                console.error("DanatePage get data list error=", error);
            }
        };
        fetchData();
    }, [currentLocale]);

    const loadData = async (locale) => {
        setNavbar(menudata?.navbar);
        setFooter(menudata?.footer);

        const menuUrlArr = getMenuUrlArr(menudata);

        try {
            const dataMap = {};

            await Promise.all(menuUrlArr.map(async (item) => {
                const d = await getSitePagesByUrl(item);
                dataMap[item] = d;
            }));

            setDataPages(dataMap);

            console.log("DanatePage get data list success.");
        } catch (error) {
            console.error("Error fetching site pages:", error);
        }

        const d = await getSitePagesByUrl('page1');
        setData1( d );
    };

    const getMenuUrlArr = (menudata) => {
        if (!menudata?.navbar?.menu || !Array.isArray(menudata.navbar.menu)) {
            return [];
        }

        return menudata.navbar.menu.map(item => {
            if (item.lref) {
                const parts = item.lref.split('/');
                return parts[parts.length - 1];
            }
            return '';
        });
    };

    const getJsonData = (data) => {
        const data_ = data?.page_json;

        if (!data_) {
            return null;
        }

        try {
            if (typeof data_ === 'object') {
                return data_;
            }
            if (typeof data_ === 'string') {
                return JSON.parse(data_);
            }
            return null;
        } catch (error) {
            console.error('Failed to parse JSON:', error);
            return null;
        }
    };

    const defaultData = Object.values(dataPages)[0]?.[0] || {}; // Перше значення з першого масиву або порожній об'єкт
    const dataToRender = (dataPages[id]?.[0] || defaultData) || {};

    return (
        <>
            {/* menudata={JSON.stringify(menudata)}<br /><br />
            dataPages={JSON.stringify(dataPages)}<br /><br />

            d1={JSON.stringify(data1)}<br /><br />
            defaultData={JSON.stringify(defaultData)}<br /><br /> */}

            <TykoLanding
                navbar={navbar}
                designType={designType}
            >
                <LandingContent
                    data={getJsonData(dataToRender)}
                    currentLocale={currentLocale}
                    designType={designType}
                />
            </TykoLanding>
        </>
    );
};

export default RcClubPageMain_BD;
