import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, FormControlLabel, Checkbox } from '@mui/material';

import { getMultilangProjectsByLang, updateOrCreateMultilangProjectByLang, createMultilangProject } from '../../../../api_sequelize/api_mproject';

import { fetchProject } from '../api/api_projects';
import {translateToUkrainian} from '../../../../api_tools/apiTranslationService';
import { changeMetafieldLine, changeMetafieldLine_value, getSubfieldValue } from '../../../../modules/CRUD_system/functions/MetafieldEditLineTools';

import { getProjectFiltres_rec_ukraine } from '../../RC_EClub/bdFunctions/getProjectFiltres_rec_ukraine';
import { LOCALES } from '../../../../i18n/locales';

// Функція для генерації об'єкта за замовчуванням
const generateDefaultObject = (columns) => {
    return columns.reduce((acc, column) => {
        acc[column.field] = column.default || '';
        return acc;
    }, {});
};

const AdminDialogCrud_multilang = ({ open, onClose, isEditMode, columns, currentItem, onSubmit, adminProjectDefaultFields }) => {
    const [multilangProject, setMultilangProject] = useState([]);
    const [currentProject1, setCurrentProject1] = useState(generateDefaultObject(columns));
    const [currentProject2, setCurrentProject2] = useState(generateDefaultObject(columns));

    const [projectIdOld, setProjectIdOld] = useState(86);
    //const [loading, setLoading] = useState(false);
    const [loadingField, setLoadingField] = useState(null);
    //const [fetchedProject, setFetchedProject] = useState([]);ENGLISH
    const filters_eng = getProjectFiltres_rec_ukraine(LOCALES.ENGLISH);
    const filters_ukr = getProjectFiltres_rec_ukraine(LOCALES.UKRAINIAN);

    // useEffect(() => {
    //     if (isEditMode) {
    //         loadData();
    //     } else {
    //         setCurrentProject1(generateDefaultObject(columns));
    //         setCurrentProject2(generateDefaultObject(columns));
    //     }
    // }, []);

    useEffect(() => {
        if (isEditMode) {
            loadData();
        } else {
            setCurrentProject1(generateDefaultObject(columns));
            setCurrentProject2(generateDefaultObject(columns));
        }
    }, [currentItem]);

    const loadData = async () => {
        try {
            let pr_id = 0;
            if (currentItem && currentItem.TykoProjectMultilang && currentItem.TykoProjectMultilang.id) {
                pr_id = currentItem.TykoProjectMultilang.id;
            }
            const p0 = await getMultilangProjectsByLang(pr_id, 'ukr');
            setMultilangProject(p0);
            const p1 = findPrByLang(p0, 'eng');
            const p2 = findPrByLang(p0, 'ukr');
            if (p1.length > 0) setCurrentProject1(p1[0]);
            if (p2.length > 0) setCurrentProject2(p2[0]);

            // const fp = await fetchProject(86);
            // setFetchedProject(fp);
        } catch (error) {
            console.error(error);
        }
    };

    const findPrByLang = (projects, lang) => {
        return projects.filter(project => project.currlang === lang);
    };

    const onChange_combo = (e, field, sufix) => {
        console.log(`onChange_combo... field=${field} val=${e.target.value}`);
        
        const selectedValue = e.target.value;
        const comboObj = getItemsByBdfield(filters_eng, field);
        const index = comboObj.indexOf(selectedValue);

        const value = comboObj[index];
        setCurrentProject1({ ...currentProject1, [field]: value });

        const comboObj2 = getItemsByBdfield(filters_ukr, field);
        const value2 = comboObj2[index];
        setCurrentProject2({ ...currentProject2, [field]: value2 });

        console.log(`onChange_combo... comboObj2=${comboObj2} index2=${index} value2=${value2}`);
    }

    const onChangeCheckBox = (e, column, category) => {
        const isChecked = e.target.checked;
        let updatedItems;
    
        console.log(`======== column.field=${column.field} currentProject1[column.field]=${currentProject1[column.field]}`);
        
        // Перевірка, чи поле column.field є рядком JSON
        if (typeof currentProject1[column.field] === 'string' && currentProject1[column.field].trim() !== '') {
            try {
                const parsedItems = JSON.parse(currentProject1[column.field]);
                
                // Перевірка, чи вже вибрано елемент
                if (isChecked && !parsedItems.includes(category)) {
                    updatedItems = [...parsedItems, category];
                } else if (!isChecked && parsedItems.includes(category)) {
                    updatedItems = parsedItems.filter((item) => item !== category);
                } else {
                    // Якщо вибір не змінився, повертаємо поточний стан без змін
                    updatedItems = parsedItems;
                }
            } catch (error) {
                console.error('Error parsing currentProject1[column.field]:', error);
                updatedItems = isChecked ? [category] : [];
            }
        } else {
            updatedItems = isChecked ? [category] : [];
        }
    
        // Перетворення масиву в строку JSON та оновлення стану для англійської версії
        const updatedProject1 = { ...currentProject1, [column.field]: JSON.stringify(updatedItems) };
        setCurrentProject1(updatedProject1);
    
        console.log(`Updated Project1: ${JSON.stringify(updatedProject1)}`);
    
        // Обробка для української версії
        const engItems = getItemsByBdfield(filters_eng, column.field);
        const ukrItems = getItemsByBdfield(filters_ukr, column.field);
    
        const selectedIndexes = updatedItems.map(item => engItems.indexOf(item));
        const updatedItemsUkr = selectedIndexes.map(index => ukrItems[index]);
    
        const updatedProject2 = { ...currentProject2, [column.field]: JSON.stringify(updatedItemsUkr) };
        setCurrentProject2(updatedProject2);
    
        console.log(`Selected indexes: ${selectedIndexes}`);
        console.log(`Updated Project2: ${JSON.stringify(updatedProject2)}`);
    };
    

    const show_columns = (data_columns, data_item, suffix = null) => {
        const data_item_ = data_item ? data_item : 'empty';

        return (
            <>
                {data_columns && data_columns.map((column) => {
                    if (column && column.type && column.field && column.headerName) {
                        switch (column.type) {
                            case 'lineedit':
                            case 'multilineedit':
                            case 'img':
                                return (
                                    <>
                                    <Box display="flex" flexDirection="row" alignItems="center" gap={0}>
                                        <TextField
                                            key={column.field + suffix}
                                            autoFocus
                                            margin="normal"
                                            id={column.field}
                                            label={column.headerName}
                                            type={column.type === 'multilineedit' ? 'text' : column.field}
                                            fullWidth
                                            variant="outlined"
                                            value={data_item_[column.field]}
                                            onChange={(e) => setCurrentItem_multilang({ ...data_item_, [column.field]: e.target.value }, suffix)}
                                            multiline={column.type === 'multilineedit'}
                                            minRows={column.type === 'multilineedit' ? 3 : 1}
                                        />
                                        {suffix && <>
                                            <Button onClick={() => handleTranslateField(data_item_, column.field, false)} disabled={loadingField === column.field}>
                                                tr
                                            </Button>
                                            {loadingField === column.field && <CircularProgress />}
                                        </>}
                                    </Box>
                                    </>
                                )
                            case 'advlineedit':
                            case 'advmultilineedit':
                                return (
                                    <>
                                    <Box display="flex" flexDirection="row" alignItems="center" gap={0}>
                                    <TextField
                                        key={column.field}
                                        autoFocus
                                        margin="normal"
                                        id={column.field}
                                        label={column.headerName}
                                        type={(column.type === 'multilineedit' || column.type === 'advmultilineedit') ? 'text' : column.field}
                                        fullWidth
                                        variant="outlined"
                                        value={getSubfieldValue(data_item_, column.field)}
                                        onChange={(e) => onChangeAvLine(e, data_item_, column, suffix)}
                                        multiline={(column.type === 'multilineedit' || column.type === 'advmultilineedit')}
                                        minRows={(column.type === 'multilineedit' || column.type === 'advmultilineedit') ? 3 : 1}
                                    />
                                    {suffix && <>
                                        <Button onClick={() => handleTranslateField(data_item_, column.field, true)} disabled={loadingField === column.field}>
                                            tr
                                        </Button>
                                        {loadingField === column.field && <CircularProgress />}
                                    </>}
                                    </Box>
                                    </>
                                )
                            case 'combo':
                                return (
                                    <FormControl fullWidth sx={{ marginTop: '8px' }}>
                                        <Typography variant="subtitle1" sx={{ marginBottom: '4px' }}>{getTitleByBdfield(suffix==='sufix'?filters_ukr:filters_eng, column.field)}</Typography>
                                        <Select
                                        id={data_item_[column.field]}
                                        value={data_item_[column.field]}
                                        //onChange={handleCategoryChange}
                                        onChange={(e) => onChange_combo(e, column.field, suffix) }
                                        >
                                        {getItemsByBdfield(suffix==='sufix'?filters_ukr:filters_eng, column.field).map((category, index) => (
                                            <MenuItem key={index} value={category} data-index={index}>{category}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl >
                                )
                            case 'checkbox':
                                return (
                                    <FormControl fullWidth sx={{ marginTop: '8px' }}>
                                    <Typography variant="subtitle1" sx={{ marginBottom: '4px' }}>{getTitleByBdfield(suffix==='sufix'?filters_ukr:filters_eng, column.field)}</Typography>
                                    {getItemsByBdfield(suffix==='sufix'?filters_ukr:filters_eng, column.field).map((category, index) => (
                                        <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                            checked={data_item_[column.field].includes(category)}
                                            onChange={(e) => onChangeCheckBox(e, column, category)}
                                            />
                                        }
                                        label={category}
                                        />
                                    ))}
                                    </FormControl>
                                ); 
                            case 'readonly':
                            case 'forcopy':
                                return (
                                    <>{column.headerName}: {data_item_[column.field]} <br/></>
                                )
                            default:
                                return <></>
                        }
                    }
                    return null;
                })}
            </>
        )
    }

    const onSubmit2 = async () => {

        try {
            let pr_id = 0;
            if (currentItem && currentItem.TykoProjectMultilang && currentItem.TykoProjectMultilang.id) {
                pr_id = currentItem.TykoProjectMultilang.id;
            }

            if (!isEditMode) {
                console.log(`createMultilangProject start`);
                pr_id = await createMultilangProject( {
                    arrlanguage: 'eng, ukr',
                    defaultlang: 'eng',
                    tags: 'rec_ukraine',
                } );
                console.log(`createMultilangProject end. id=${pr_id}`);
            }

            if (pr_id) {
                currentProject1.currlang = 'eng';
                currentProject2.currlang = 'ukr';

                adminProjectDefaultFields && adminProjectDefaultFields.map((column) => {
                    currentProject1[column.field] = column.defValue;
                    currentProject2[column.field] = column.defValue;
                })
                // if (isEditMode) {
                //     const p1 = findPrByLang(multilangProject, 'eng');
                //     const p2 = findPrByLang(multilangProject, 'ukr');
                //     currentProject1.mproj_id = 'eng';
                //     currentProject2.mproj_id = 'ukr';
                // }

                console.log(`updateOrCreateMultilangProjectByLang start`);
                await updateOrCreateMultilangProjectByLang(pr_id, 'eng', currentProject1);
                await updateOrCreateMultilangProjectByLang(pr_id, 'ukr', currentProject2);
                console.log(`updateOrCreateMultilangProjectByLang end`);
            }

            onClose();
            onSubmit();
        } catch (error) {
            console.error('Error updating or creating multilang projects:', error);
        }
    };

    // const loadFetchProject = async () => {
    //     try {
    //         const fp = await fetchProject(projectIdOld);
            
    //         if (fp && fp.length > 0) {
    //             const updatedProject = { 
    //                 ...fp[0],
    //                 project_id: currentProject1.project_id,
    //                 currlang: currentProject1.currlang,
    //                 mproj_id: currentProject1.mproj_id 
    //             };
                
    //             setCurrentProject1(updatedProject);
                
    //             console.log(`loadFetchProject project_id=${updatedProject.project_id} currlang=${updatedProject.currlang} mproj_id=${updatedProject.mproj_id}`);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching project:', error);
    //     }
    // };

    const setCurrentItem_multilang = (item, suffix) => {
        if (suffix === 'sufix') {
            setCurrentProject2(item);
        } else {
            setCurrentProject1(item);
        }
    };

    const onChangeAvLine = (e, currentItem, column, suffix) => {
        console.log(`onChangeAvLine...`);
        const [field, subfield] = column.field.split(':');
        
        const advVal = changeMetafieldLine(e, currentItem, column);
        //console.log(`advVal### = ${JSON.stringify(currentItem)}`);
        if (suffix === 'sufix') {
            setCurrentProject2({ ...currentItem, [field]: advVal });
        } else {
            setCurrentProject1({ ...currentItem, [field]: advVal });
        }
        //setCurrentItem({ ...currentItem, [field]: advVal });
    }

    const handleTranslateField = async (data_item__, field, isadv) => {
        console.log(`handleTranslateField start. field=${field} isadv=${isadv}`);
        function getFirstPart(inputString) {
            const parts = inputString.split(':');
            return parts[0];
        }
        const text = isadv ? getSubfieldValue(currentProject1, field): currentProject1[field]
        console.log(`text=${currentProject2[getFirstPart(field)]} `);
        if (!text) {
            console.log(`=== Warning!!! text is null`);
            return;
        }
    
        setLoadingField(field);
        const translatedProject = { ...currentProject2 };
        for (const column of columns) {
            if (field === column.field) {
                if (column.type === 'lineedit' || column.type === 'multilineedit') {
                    //const text = currentProject1[column.field];
                    if (text) {
                        console.log(`=== handleTranslate field=${column.field} text=${text}`);
                        const translated = await translateToUkrainian(text)
                        console.log(`result field=${column.field} text=${translated}`);
                        translatedProject[column.field] = translated;
                    }
                    setCurrentProject2(translatedProject);
                } else if ( (column.type === 'advlineedit' || column.type === 'advmultilineedit') && column.field) {
                    console.log(`=== handleTranslate field=${column.field}`);
                    const translated = await translateToUkrainian(text);
                    //console.log(`result field=${column.field} text=${translated}`);
                    const advVal = changeMetafieldLine_value(translated, currentProject2, column);
                    //console.log(`==== result advVal=${advVal} `);
                    
                    setCurrentProject2({ ...currentProject2, [getFirstPart(field)]: advVal });

                } else if (column.type !== 'readonly') {
                    const text = currentProject1[column.field];
                    translatedProject[column.field] = text;
                    setCurrentProject2(translatedProject);
                }
            }
        }
        
    
        setLoadingField(null);
        console.log(`handleTranslateField end`);
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>
                <Typography variant="h3" style={{ fontWeight: 'bold', color: 'darkblue' }}>
                    {isEditMode ? 'Edit Item' : 'Add Item'}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="h5" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                        {isEditMode ? 'Update the item details.' : 'Enter the details of the new item.'}
                    </Typography>
                </DialogContentText>
                <Box display="flex" flexDirection='row' width='100%' gap={1}>
                    <Box display="flex" flexDirection='column' width='50%' gap={1}>
                        Дефолтна мова
                        {/* <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                        <Button onClick={loadFetchProject}>loadFetchProject</Button>
                            <TextField
                                label="Edit Old Project ID"
                                variant="outlined"
                                value={projectIdOld}
                                onChange={(e) => setProjectIdOld(e.target.value)}
                            />
                        </Box> */}
                        {/* currentItem={JSON.stringify(currentProject1)} */}
                        {show_columns(columns, currentProject1)}
                    </Box>
                    <Box display="flex" flexDirection='column' width='50%' gap={1}>
                        Мова що редагується
                        {/* <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                            <Button onClick={handleTranslateAll} disabled={loading}>
                                translate to ukr
                            </Button>
                            {loading && <CircularProgress />}
                        </Box> */}
                        {/* currentItem={JSON.stringify(currentProject2)} */}
                        {show_columns(columns, currentProject2, 'sufix')}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSubmit2}>{isEditMode ? 'Update' : 'Add'}</Button>
            </DialogActions>
        </Dialog>
    );

    

    function getItemsByBdfield(data, bdfield) {
        // Шукаємо об'єкт з відповідним bdfield
        const category = data.find(item => item.bdfield === bdfield);
        // Перевіряємо, чи такий об'єкт був знайдений
        if (category) {
            // Повертаємо елементи, якщо такий bdfield був знайдений
            return category.items;
        } else {
            // Якщо не знайдено, повертаємо порожній масив
            return null;
        }
    }
    
    function getTitleByBdfield(data, bdfield) {
        // Шукаємо об'єкт з відповідним bdfield
        const category = data.find(item => item.bdfield === bdfield);
        // Перевіряємо, чи такий об'єкт був знайдений
        if (category) {
            // Повертаємо елементи, якщо такий bdfield був знайдений
            return category.title;
        } else {
            // Якщо не знайдено, повертаємо порожній масив
            return null;
        }
    }
};

export default AdminDialogCrud_multilang;
