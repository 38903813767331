import React from 'react';
import { Button, IconButton, Typography, Box } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import SaveIcon from '@mui/icons-material/Save';

const ContactCard = ({ name, phoneNumber }) => {
    const handleSaveContact = () => {
        const vCardData = `BEGIN:VCARD\nVERSION:3.0\nFN:${name}\nTEL;TYPE=CELL:${phoneNumber}\nEND:VCARD`;
        const blob = new Blob([vCardData], { type: 'text/vcard' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}.vcf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    return (
        <Box display="flex" alignItems="center" mb={2}>
            {/* <Typography variant="h6" component="div">
                {name}
            </Typography>
            <Typography variant="body1" sx={{ marginLeft: '8px', marginRight: '8px' }}>
                {phoneNumber}
            </Typography> */}

            {/* Buttons are only visible on mobile devices */}
            <Box 
                display={{ xs: 'flex', md: 'none' }} ml={1}
            >
                <IconButton
                    component="a"
                    href={`tel:${phoneNumber}`}
                    color="primary"
                    size="small"
                    aria-label="Call"
                >
                    <PhoneIcon />
                </IconButton>
                <IconButton
                    onClick={handleSaveContact}
                    color="primary"
                    size="small"
                    aria-label="Save to contacts"
                >
                    <SaveIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ContactCard;