import React, { useState, useEffect } from 'react';
import { TextField, Button, Box } from '@mui/material';
import axios from 'axios';

import {fsCreateFolder, fsRenameItem, fsDeleteItem} from './fstools';

const FileActions = ({ path, oldFile, newFolder, onPathChange }) => {
    const [newFolderName, setNewFolderName] = useState(newFolder);
    const [newName, setNewName] = useState(oldFile && oldFile.name);
    
    useEffect(() => {
        setNewName(oldFile && oldFile.name);
    }, [oldFile]);

    const createFolder = async () => {
        if (await fsCreateFolder(path, newFolderName)) {
            onPathChange(path); // Refresh the list
        } 
    };

    const renameItem = async () => {
        if (await fsRenameItem(path, oldFile, newName)) {
            onPathChange(path); // Refresh the list
        } 
    };

    const deleteItem = async () => {
        if (await fsDeleteItem(path, oldFile)) {
            onPathChange(path); // Refresh the list
        } 
    };

    return (
        <Box>

        {newFolder &&
        <Box display="flex" alignItems="center" mb={2}>
            <TextField
                key={'FileActions1'}
                label="New Folder Name"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
            />
            <Button variant="contained" onClick={createFolder}>Create Folder</Button>
        </Box>}

        {oldFile && 
        <Box display="flex" alignItems="center" mb={2}>
            <>oldName='{oldFile && oldFile.name}'</>
            <TextField
                key={'FileActions3'}
                label="New Name"
                value={newName}
                fullWidth
                onChange={(e) => setNewName(e.target.value)}
            />
            <Button variant="contained" onClick={renameItem}>Rename</Button>
        </Box>}

        {/* {oldName && 
        <Box display="flex" alignItems="center" mb={2}>
            <>Name='{oldName}'</>
            <Button variant="contained" onClick={deleteItem}>Delete</Button>
        </Box>} */}
        
        </Box>
    );
};

export default FileActions;
