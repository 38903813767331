import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Grid, Box, IconButton, Tooltip, Paper } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileActions from './FileActions';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FileUpload from './FileUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ShareIcon from '@mui/icons-material/Share';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Login from '../Login';
import FileListView from './FileListView';
import FileCardView from './FileCardView';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { handleCopyToClipboard } from '../../../modules/CRUD_system/functions/toolsFuncs';
import { fsFetchFiles, fsCreateFolder, fsRenameItem, fsDeleteItem, fsSecureLink } from './fstools';

const FileExplorer = ({ rootFolder }) => {
    const root_folder = rootFolder ? rootFolder : '/clubsitecreator';

    const [path, setPath] = useState(root_folder);
    const [selectedFile, setSelectedFile] = useState();
    const [files, setFiles] = useState([]);
    const [newFolder, setNewFolder] = useState();
    const [isUpload, setIsUpload] = useState(false);
    const [shareLinks, setShareLinks] = useState({});
    const [viewMode, setViewMode] = useState('list'); // Додаємо стан для зміни режиму перегляду

    useEffect(() => {
        fetchFiles();
    }, [path]);

    const fetchFiles = async () => {
        const arrFiles = await fsFetchFiles(path);
        if (arrFiles) {
            fetchShareLinks(arrFiles);
            setFiles(arrFiles);
        }
    };

    const fetchShareLinks = async (files) => {
        const links = {};
        for (const file of files) {
            if (!file.dir) {
                const sharelink = await fsSecureLink(file.path);
                links[file.name] = sharelink;
            }
        }
        setShareLinks(links);
    };

    const handlePathChange = (newPath, name) => {
        console.log(`handlePathChange newPath=${newPath} name=${name}`);

        function getParentPath(currentPath) {
            const segments = currentPath.split('/').filter(Boolean); // Розбиваємо шлях на сегменти

            // Якщо поточний шлях вже дорівнює root_folder, не дозволяємо підніматися вище
            if ('/' + segments.join('/') === root_folder) {
                return root_folder;
            }

            segments.pop(); // Видаляємо останній сегмент
            const newPath = '/' + segments.join('/'); // Об'єднуємо сегменти назад у рядок

            // Якщо новий шлях стає меншим за root_folder, повертаємо root_folder
            return newPath.startsWith(root_folder) ? newPath : root_folder;
        }

        if (name === '..') {
            setPath(getParentPath(newPath));
        } else {
            setPath(newPath);
        }
        setSelectedFile(null);
    };

    const handleResult = (res) => {
        console.log(`handleResult res=${res}`);
        
        setNewFolder(null);
        setSelectedFile(null);
        setIsUpload(false);

        fetchFiles();
    };

    const handleItemClick = (item) => {
        setNewFolder(null);
        if (item.dir) {
            handlePathChange(item.path, item.name);
        } else {
            console.log(`onFileSelected selFile=${item.name}`);
            setSelectedFile(item);
        }
        setIsUpload(false);
        setNewFolder(null);
    };

    const handleEdit = (item) => {
        console.log(`handleEdit name=${item.name}`);
        if (item.dir) {
            setSelectedFile(item);
        } else {
            setSelectedFile(item);
        }
        setIsUpload(false);
    };

    const handleDelete = async (file) => {
        const success = await fsDeleteItem(path, file.name);
        if (success) {
            fetchFiles();
        }
    };

    const handleNewFolder = async () => {
        setNewFolder(newFolder ? null : 'new folder');
        setSelectedFile(null);
        setIsUpload(false);
    };

    const handleBtnUpload = async () => {
        setIsUpload(!isUpload);
        setNewFolder(null);
        setSelectedFile(null);
    };

    const handleShare = async (file) => {
        handleCopyToClipboard(shareLinks[file.name]);
    };

    const handleShareList = async () => {
        const allLinks = files
            .filter(file => !file.dir)
            .map(file => shareLinks[file.name])
            .join(', ');
        handleCopyToClipboard(allLinks);
    };

    const showToolBarButton = ({ tooltip, handleBtn, isActive, iconbtn }) => {
        return (
            <Tooltip title={tooltip}>
                <IconButton
                    edge="end"
                    onClick={handleBtn}
                    sx={{
                        color: isActive ? 'default' : 'primary.main'
                    }}
                >
                    {iconbtn}
                </IconButton>
            </Tooltip>
        );
    };

    return (
        <Container sx={{ bgcolor: '#f5f5f5', minHeight: '100vh', padding: 3 }}>
            <Typography variant="h4" gutterBottom>File Explorer</Typography>
            <Login handleResult={handleResult} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ padding: 2 }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                            <Box flexGrow={1} mr={2}>
                                <Typography
                                    variant="body1"
                                    noWrap
                                    sx={{
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        padding: '8px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {path}
                                </Typography>
                            </Box>
                            <Button variant="contained" onClick={() => handlePathChange(root_folder)}>Go to Root</Button>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                            {showToolBarButton({ tooltip: "Створити каталок", handleBtn: handleNewFolder, isActive: !!newFolder, iconbtn: <CreateNewFolderIcon /> })}
                            {showToolBarButton({ tooltip: "Завантажити файли", handleBtn: handleBtnUpload, isActive: isUpload, iconbtn: <CloudUploadIcon /> })}
                            {showToolBarButton({ tooltip: "Скопіювати лінки на всі файли", handleBtn: handleShareList, isActive: false, iconbtn: <ShareIcon /> })}
                            <Box flexGrow={1} />
                            {showToolBarButton({ tooltip: "Перегляд списком", handleBtn: () => setViewMode('list'), isActive: viewMode === 'list', iconbtn: <ViewListIcon /> })}
                            {showToolBarButton({ tooltip: "Перегляд картками", handleBtn: () => setViewMode('card'), isActive: viewMode === 'card', iconbtn: <ViewModuleIcon /> })}
                        </Box>
                    </Paper>
                </Grid>

                {isUpload && (
                    <Grid item xs={12}>
                        <FileUpload path={path} handleResult={handleResult} />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <FileActions path={path} oldFile={selectedFile} newFolder={newFolder} onPathChange={handleResult} />
                </Grid>

                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ padding: 2 }}>
                        {viewMode === 'list' ? (
                            <FileListView 
                                files={files}
                                shareLinks={shareLinks}
                                handleItemClick={handleItemClick}
                                handleShare={handleShare}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete} />
                        ) : (
                            <FileCardView 
                                files={files}
                                shareLinks={shareLinks}
                                handleItemClick={handleItemClick}
                                handleShare={handleShare}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete} />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default FileExplorer;
