import {getMenuStructure} from '../bdFunctions/getMenuStructure';
import {getPagesContent} from '../bdFunctions/getPagesContent';

import RcClubPageMain from './RcClubPageMain';

const FsIyfrPage = () => {
    const  designType='chicago';

    return (
        <RcClubPageMain 
            menufunc={getMenuStructure} 
            pagesContent={getPagesContent}
            designType={designType}
            tags='rec_ukraine'
        >
        </RcClubPageMain>
    );
}

export default FsIyfrPage;