import React, { useState } from 'react';
import { Button, Box, CircularProgress, Typography, List, ListItem, ListItemText, Paper, Container } from '@mui/material';
import DndArea from '../../../components2/DnD/DndArea';
import { uploadFileToFStorage } from '../../../api_fs/apifs';

const FileUpload = ({ path, handleResult }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progressText, setProgressText] = useState('');

  const handleFileChange = (e) => {
    setFiles((prevFiles) => [...prevFiles, ...Array.from(e.target.files)]);
  };

  const handleDndFilesChange = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const handleUpload = async () => {
    setLoading(true);
    setProgressText(`Завантажено 0 з ${files.length} файлів`);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      try {
        await uploadFileToFStorage(file, path);
        setProgressText(`Завантажено ${i + 1} з ${files.length} файлів`);
      } catch (error) {
        console.error('File upload failed!', error);
      }
    }

    setLoading(false);
    handleResult(true); // Refresh the list
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
        <Typography variant="h5" gutterBottom>
          Завантаження файлів
        </Typography>
        
        <DndArea handleDndFilesChange={handleDndFilesChange} />

        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
          <input type="file" onChange={handleFileChange} multiple style={{ display: 'none' }} id="file-upload-input" />
          <label htmlFor="file-upload-input">
            <Button variant="contained" component="span" sx={{ mb: 2 }}>
              Вибрати файли
            </Button>
          </label>

          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={files.length === 0 || loading}
          >
            Завантажити файли
          </Button>

          {loading && (
            <Box mt={2}>
              <CircularProgress />
            </Box>
          )}

          {progressText && (
            <Typography variant="body2" mt={2}>
              {progressText}
            </Typography>
          )}
        </Box>

        <List sx={{ mt: 2, width: '100%' }}>
          {files.map((file, index) => (
            <ListItem key={index}>
              <ListItemText primary={file.name} secondary={`${file.size} bytes`} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default FileUpload;
