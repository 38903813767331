import React from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div style={{color: 'red'}}>{text}</div>;

const LandingMapComponent = ({ center, zoom }) => {
    return (
        // Розміри контейнера для карти можна налаштувати
        <div style={{ height: '400px', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyAbxQlFr83tu79J4Kp3HiCxoatPt3RWQ2E' }}
                defaultCenter={center}
                defaultZoom={zoom}
            >
                <AnyReactComponent
                    lat={center.lat}
                    lng={center.lng}
                    text="Моя локація"
                />
            </GoogleMapReact>
        </div>
    );
};

export default LandingMapComponent;
