import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';

import {apiGetEvents, createEvent, updateEvent, deleteEvent } from '../../../../api_sequelize/api_events';

import CrudTable from './CrudTable';
import AdminDialogCrud3 from './AdminDialogCrud3';

const adminUserDefaultFields = [
    { field: 'type', defValue: 'google_event' },
]

const eventColumns = [
    { field: 'id', headerName: 'id', type: 'id', default: '' },
    { field: 'title', headerName: 'title', type: 'lineedit', default: '' },	
    { field: 'start', headerName: 'start', type: 'lineedit', default: '' },
    { field: 'end', headerName: 'end', type: 'lineedit', default: '' },
    { field: 'description', headerName: 'description', type: 'lineedit', default: '' },
    { field: 'colorId', headerName: 'colorId', type: 'lineedit', default: '' },
    { field: 'additionalFields:regLink', headerName: 'regLink', type: 'advlineedit', default: '' },
    { field: 'additionalFields:siteLink', headerName: 'siteLink', type: 'advlineedit', default: '', tablehided: 'true' },
    { field: 'additionalFields:docsLink', headerName: 'docsLink', type: 'advmultilineedit', default: '', tablehided: 'true' },
    { field: 'additionalFields:photoLink', headerName: 'photoLink', type: 'advmultilineedit', default: '', tablehided: 'true' },
    { field: 'additionalFields:videoLink', headerName: 'videoLink', type: 'advmultilineedit', default: '', tablehided: 'true' },
];

const generateDefaultObject = (columns) => {
    return columns.reduce((acc, column) => {
        acc[column.field] = column.default;
        return acc;
    }, {});
};

const CRUDEvents2 = ({tags}) => {
    const [events, setEvents] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentEvent, setCurrentEvent] = useState(generateDefaultObject(eventColumns));
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const fetchedEvents = await apiGetEvents();
            const filteredData = tags ? fetchedEvents.filter(item => item.tags === tags) : fetchedEvents;

            setEvents(filteredData);
        } catch (error) {
            console.error(error);
        }
    };

    const handleEditEvent = (event = generateDefaultObject(eventColumns)) => {
        setCurrentEvent(event);
        setIsEditMode(!!event.id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        console.log('=== handleSubmit...');

        const currentEvent_new = { ...currentEvent };
        adminUserDefaultFields.forEach(({ field, defValue }) => {
            currentEvent_new[field] = defValue;
        });
        console.log('handleSubmit event='+JSON.stringify(currentEvent_new))

        try {
            if (isEditMode) {
                await updateEvent(currentEvent_new.id, currentEvent_new);
            } else {
                await createEvent(currentEvent_new);
            }
            handleClose();
            fetchData();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async (eventId) => {
        console.log('=== handleDelete... eventId='+eventId);
        const confirmDelete = window.confirm('Чи дійсно хочете видалити цю подію?');
        if (!confirmDelete) return;
    
        try {
            await deleteEvent(eventId);
            fetchData();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <Box display="flex" flexDirection="row" pt="8px" sx={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: 'white' }}>
                <Button variant="contained" color="primary" onClick={() => handleEditEvent()}>
                    Add Event
                </Button>

                <Box width="100%">
                </Box>
            </Box>
            
            <CrudTable data={events} columns={eventColumns} onEdit={handleEditEvent} onDelete={handleDelete} />

            <AdminDialogCrud3
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                isEditMode={isEditMode}
                columns={eventColumns}
                currentItem={currentEvent}
                setCurrentItem={setCurrentEvent}
            />
        </div>
    );
}

export default CRUDEvents2;
