import React, { useEffect, useState } from "react";
import './PhotoCarusel.css';

import { Button, Box, Container, Grid, Typography } from '@mui/material'

const VideoHeaderForLanding = ({video}) => {useEffect(() => {

    const fetchData = async () => {
        try {

            const video = document.getElementById('myVideo');
            video.autoplay = true;
            video.muted = true;

        } catch (error) {
            console.log("VideoHeaderForLanding error="+error);
        }
        };
        fetchData();
    }, [])

    return (
        <video width="100%"  controls autoplay muted loop id="myVideo">
            <source src={video} type="video/mp4" />
            Ваш браузер не підтримує відтворення відео.
        </video>
    );
}

export default VideoHeaderForLanding;
