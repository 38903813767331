import React, { useEffect, useRef } from 'react';
import QRCode from 'qrcode-generator';
import { Box } from '@mui/material';
import { loadImage } from 'canvas';

const QRGenerator = ({ data, logo, template, width=600, height=600 }) => {
  const canvasRef = useRef(null);

  const getMinQRCodeVersion = (data, errorCorrectionLevel) => {
    for (let version = 4; version <= 40; version++) {
      const qr = QRCode(version, errorCorrectionLevel);
      qr.addData(data);
      try {
        qr.make();
        return version;
      } catch (e) {
        // continue to the next version if the current version is not sufficient
      }
    }
    throw new Error('Data is too long to be encoded in a QR code');
  };

  useEffect(() => {
    const errorCorrectionLevel = 'M';
    let version;
    try {
      version = getMinQRCodeVersion(data, errorCorrectionLevel);
      console.log(`getMinQRCodeVersion v=${version} l=${errorCorrectionLevel}`);
    } catch (e) {
      console.error(e.message);
      return;
    }

    const qr = QRCode(version, errorCorrectionLevel);
    qr.addData(data);
    qr.make();

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const tileW = canvas.width / qr.getModuleCount();
    const tileH = canvas.height / qr.getModuleCount();

    if (template === 'QRTEMPLATE1') {
      // Кольори для кружечків та фону
      ctx.fillStyle = '#FFFFFF'; // Білий фон
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Функція для малювання квадратів по кутах з закругленими кутами
      const drawCornerSquares = (row, col) => {
        const size = 7;
        const radius = tileW * 1.5;

        ctx.fillStyle = '#0D47A1';
        ctx.beginPath();
        ctx.moveTo(col * tileW + radius, row * tileH);
        ctx.lineTo((col + size) * tileW - radius, row * tileH);
        ctx.quadraticCurveTo((col + size) * tileW, row * tileH, (col + size) * tileW, row * tileH + radius);
        ctx.lineTo((col + size) * tileW, (row + size) * tileH - radius);
        ctx.quadraticCurveTo((col + size) * tileW, (row + size) * tileH, (col + size) * tileW - radius, (row + size) * tileH);
        ctx.lineTo(col * tileW + radius, (row + size) * tileH);
        ctx.quadraticCurveTo(col * tileW, (row + size) * tileH, col * tileW, (row + size) * tileH - radius);
        ctx.lineTo(col * tileW, row * tileH + radius);
        ctx.quadraticCurveTo(col * tileW, row * tileH, col * tileW + radius, row * tileH);
        ctx.closePath();
        ctx.fill();

        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect((col + 1) * tileW, (row + 1) * tileH, tileW * 5, tileH * 5);
        ctx.fillStyle = '#0D47A1';
        ctx.fillRect((col + 2) * tileW, (row + 2) * tileH, tileW * 3, tileH * 3);
      };

      // Малювання квадратів по кутах
      drawCornerSquares(0, 0);
      drawCornerSquares(0, qr.getModuleCount() - 7);
      drawCornerSquares(qr.getModuleCount() - 7, 0);

      // Рисування QR-коду з кружечками
      for (let row = 0; row < qr.getModuleCount(); row++) {
        for (let col = 0; col < qr.getModuleCount(); col++) {
          if (
            (row < 7 && col < 7) ||
            (row < 7 && col >= qr.getModuleCount() - 7) ||
            (row >= qr.getModuleCount() - 7 && col < 7)
          ) {
            continue; // Пропуск квадратів по кутах
          }
          ctx.fillStyle = qr.isDark(row, col) ? '#0D47A1' : '#FFFFFF';
          const w = (Math.ceil((col + 1) * tileW) - Math.floor(col * tileW));
          const h = (Math.ceil((row + 1) * tileH) - Math.floor(row * tileH));
          ctx.beginPath();
          ctx.arc(Math.floor(col * tileW) + w / 2, Math.floor(row * tileH) + h / 2, Math.min(w, h) / 2.5, 0, 2 * Math.PI, false); // Зменшення розміру кружечків
          ctx.fill();
        }
      }

      // Додавання логотипу
      if (logo) {
        loadImage(logo).then(image => {
          const logoWidth = canvas.width / 4;
          const logoHeight = (image.height / image.width) * logoWidth;
          const padding = 10; // Білий паддінг 20px
          const totalLogoWidth = logoWidth + padding * 2;
          const totalLogoHeight = logoHeight + padding * 2;
          const logoX = (canvas.width - totalLogoWidth) / 2;
          const logoY = (canvas.height - totalLogoHeight) / 2;

          // Створення білого фону під логотипом з паддінгом
          ctx.fillStyle = '#FFFFFF';
          ctx.fillRect(logoX, logoY, totalLogoWidth, totalLogoHeight);

          // Малювання логотипу з паддінгом
          ctx.drawImage(image, logoX + padding, logoY + padding, logoWidth, logoHeight);
        });
      }
    } else {
      // Класичний темплейт
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      for (let row = 0; row < qr.getModuleCount(); row++) {
        for (let col = 0; col < qr.getModuleCount(); col++) {
          ctx.fillStyle = qr.isDark(row, col) ? '#000000' : '#FFFFFF';
          ctx.fillRect(col * tileW, row * tileH, tileW, tileH);
        }
      }
    }
  }, [data, logo, template]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <canvas ref={canvasRef} width={width} height={height} />
    </Box>
  );
};

export default QRGenerator;
