import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAllSiteMenus, getSiteMenusBySiteId, createSiteMenu, updateSiteMenu, deleteSiteMenu } from '../../api_sequelize/api_sites';
import { Container, Button } from '@mui/material';
import { Paper } from '@mui/material';
import CRUD_DbAdmin from '../RC_Cherkasy/rotariansBD/adminsPages/CRUD_DbAdmin';

const getSiteMunusWithParaams = async (params) => {
    //console.log("===== getSiteMunusWithParaams params="+JSON.stringify(params));
    const siteId = params && params.siteId.siteId;
    return await getSiteMenusBySiteId(siteId)
}

const crud_functions = {
    loadItems: getSiteMunusWithParaams,
    createItem: createSiteMenu,
    updateItem: updateSiteMenu,
    deleteItem: deleteSiteMenu,
}

const AdminSiteMenus = () => {
    const [sites, setSites] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const siteId = searchParams.get('id');
    const loadParams = { siteId: {siteId} };

    const tableTitles = [
        { field: 'id',      headerName: 'id', type: 'lineedit' },
        { field: 'siteId',  headerName: 'siteId', type: 'lineedit', default: siteId },
        { field: 'menuId',  headerName: 'menuId', type: 'lineedit', default: '', tablehided: 'true' },
        { field: 'btnText', headerName: 'btnText', type: 'lineedit', default: '' },
        { field: 'btnUrl',  headerName: 'btnUrl', type: 'lineedit', default: '' },
        { field: 'lang',    headerName: 'lang:', type: 'lineedit', default: '', tablehided: 'true' },
    ];

    const refreshData = async (data) => {
        setSites(data);
    };
    const fRowClicked = (row) => {
        const searchParams = new URLSearchParams(location.search);

        const currId = sites && sites[row] && sites[row].id;
        searchParams.set('menuid', currId);
        navigate(`${location.pathname}?${searchParams.toString()}`);
    }
    return (
        <Container>
        <Paper>
            Admin Site  Menu<br/>
            Меню сайту з id={siteId}
            
            <CRUD_DbAdmin
                data={sites}
                tableTitles={tableTitles}
                crudFunctions={crud_functions}
                loadParams={loadParams}
                refreshData= {refreshData}
                fRowClicked={fRowClicked}
            />
        </Paper>
        </Container>
    );
};

export default AdminSiteMenus;
