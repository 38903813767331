import { LOCALES } from '../../../../i18n/locales';
export const getProjectFiltres_rec_ukraine = (local) => {
    return local===LOCALES.UKRAINIAN ? data_ukr : data_eng;
}

const data_eng = [
    {
        title: 'Rotary Area of Focus:',
        bdfield: 'category',
        items: [
            "1. Promoting peace",
            "2. Fighting disease",
            "3. Providing clean water, sanitation, and hygiene",
            "4. Saving mothers and children",
            "5. Supporting education",
            "6. Growing local economies",
            "7. Protecting the environment",
        ],
    },
    {
        title: 'Project category:',
        bdfield: 'category2',
        items: [
            "Mental Health",
            "Internally displaced people",
            "Demining",
            "Medical",
            "Community development",
            "Humanitarian",
            "Children and Education",
            "Others",
        ],
    },
    {
        title: 'Realization Stage:',
        bdfield: 'status',
        items: [
            "Offered, looking for global grant partners",
            "Offered, looking for other partners",
            "Financed and in process of realization", 
            "Permanently active",
            "Succesfuly finished",
        ],
    },
    {
        title: 'Budget:',
        bdfield: 'budget',
        items: [
            "<2000$",
            "2,000-10,000$",
            "10,000-55,000$",
            ">55,000$",
        ],
    },
//     {
//         title: 'Rotary Club Type:',
//         clubtype: [
//             "Rotary",
//             "Rotaract"
//         ],
//     },
];

const data_ukr = [
    {
        title: 'Напрямки служіння Ротарі:',
        bdfield: 'category',
        items: [
            "1. Сприяння миру",
            "2. Боротьба з хворобами",
            "3. Забезпечення чистою водою, санітарне та гігієнічне забезпечення",
            "4. Підтримка матері та дитини",
            "5. Підтримка освіти",
            "6. Забезпечення зростання місцевої економіки",
            "7. Захист довкілля",
        ],
    },
    {
        title: 'Категорія проекту:',
        bdfield: 'category2',
        items: [
            "Ментальне Здоров'я",
            "Внутрішньо переміщені особи",
            "Розмінування",
            "Медицина",
            "Розвиток Громад",
            "Гуманітарні",
            "Молодь та Освіта",
            "Інше",
        ],
    },
    {
        title: 'Етап реалізації:',
        bdfield: 'status',
        items: [
            "Запропоновано, пошук партнерів глобальних грантів",
            "Запропоновано, пошук інших партнерів",
            "Проект профінансовано і реалізується", 
            "Постійний проект",
            "Успішно завершено",
        ],
    },
    {
        title: 'Бюджет:',
        bdfield: 'budget',
        items: [
            "<2000$",
            "2,000-10,000$",
            "10,000-55,000$",
            ">55,000$",
        ],
    },
//     {
//         title: 'Rotary Club Type:',
//         clubtype: [
//             "Rotary",
//             "Rotaract"
//         ],
//     },
];