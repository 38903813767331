import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

const TableLineComponent = ({ data, tableTitles, addNumber }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = React.useMemo(() => {
        if (!orderBy) return data;
        return data.slice().sort((a, b) => {
            if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
            if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
            return 0;
        });
    }, [data, order, orderBy]);

    return (
        <Table>
            <TableHead>
                <TableRow sx={{ backgroundColor: "#98c8ff" }}>
                    {addNumber && <TableCell key={-1}>№</TableCell>}
                    {tableTitles && Object.keys(tableTitles).map((item) => (
                        <TableCell key={item}>
                            <TableSortLabel
                                active={orderBy === item}
                                direction={orderBy === item ? order : 'asc'}
                                onClick={() => handleSortRequest(item)}
                            >
                                {tableTitles[item]}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {sortedData && sortedData.map((dataItem, index) => (
                    <TableRow key={dataItem.id}>
                        {addNumber && <TableCell key={index}>{index + 1}</TableCell>}
                        {tableTitles && Object.keys(tableTitles).map((item) => (
                            <TableCell key={item}>
                                {dataItem[item]}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default TableLineComponent;
