import React, { useState } from 'react';
import axios from 'axios';

//const SERVERURL = 'https://apitools.rotaryd2232ukraine.org';
const SERVERURL = 'http://localhost:3001';

function AudioMerger() {
    const [files, setFiles] = useState([]);
    const [mergedFileUrl, setMergedFileUrl] = useState(null);

    const handleFileChange = (e) => {
        setFiles([...e.target.files]);
    };

    const handleMerge = async () => {
        const formData = new FormData();
        files.forEach(file => formData.append('files', file));

        try {
            const response = await fetch(SERVERURL+'/api/merge-audio', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            setMergedFileUrl(data.url);
        } catch (error) {
            console.error('Error merging audio files:', error);
        }
    };

    return (
        <div>
            <h2>Merge Audio Files</h2>
            <input type="file" multiple onChange={handleFileChange} />
            <button onClick={handleMerge}>Merge Files</button>

            {mergedFileUrl && (
                <div>
                    <h3>Merged File:</h3>
                    <audio controls>
                        <source src={mergedFileUrl} type="audio/mp3" />
                    </audio>
                    <a href={mergedFileUrl} download="merged.mp3">Download Merged File</a>
                </div>
            )}
        </div>
    );
}

export default AudioMerger;
