// components/LandingPdfViewer.js
import React from 'react';
import BtnDownloadFrame from './components/BtnDownloadFrame'; // Імпортуємо наш новий компонент

const LandingPdfViewer = ({ fileUrl, size }) => {
    // URL для Google Drive PDF Viewer
    const viewerUrl = fileUrl;

    // Функція для виправлення URL Google Drive
    const fixGoogleLink = (link) => {
        const newlink = link ? link.replace("https://drive.google.com/open?id=", "https://drive.google.com/file/d/") : null;
        return newlink ? newlink.replace("/view?usp=sharing", "") + "/preview" : "";
    };

    // Стиль для контейнера
    const containerStyle = {
        width: '100%',
        height: '100vh',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        backgroundColor: '#f0f0f0',
    };

    return (
        <BtnDownloadFrame style={containerStyle} downloadUrl={fixGoogleLink(viewerUrl)}>
            {viewerUrl ? (
                <iframe
                    src={fixGoogleLink(viewerUrl)}
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    allow="autoplay"
                    title="PDF Viewer"
                ></iframe>
            ) : null}
        </BtnDownloadFrame>
    );
};

export default LandingPdfViewer;
