import React from 'react';
import { Grid, Card, CardContent, CardActions, IconButton, Typography, Box } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const FileCardView = ({
    files,
    shareLinks,
    handleItemClick,
    handleShare,
    handleEdit,
    handleDelete
}) => {
    return (
        <Grid container spacing={3}>
            {files.map(file => (
                <Grid item xs={12} sm={6} md={4} key={file.name}>
                    <Card>
                        <CardContent onClick={() => handleItemClick(file)}>
                            <Box display="flex" alignItems="center" mb={2}>
                                {file.dir ? <FolderIcon /> : <InsertDriveFileIcon />}
                                <Typography
                                    variant="h6"
                                    component="div"
                                    gutterBottom
                                    sx={{
                                        flexGrow: 1,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        ml: 1,
                                    }}
                                >
                                    {file.name}
                                </Typography>
                            </Box>
                            {file.media === 'image' && (
                                <Box
                                    component="img"
                                    src={shareLinks[file.name]}
                                    alt={file.name}
                                    sx={{
                                        maxWidth: '100%',
                                        maxHeight: '300px',
                                        objectFit: 'contain',
                                        display: 'block',
                                        mx: 'auto',
                                    }}
                                />
                            )}
                            <Typography variant="body2" color="text.secondary">
                                {file.size} bytes
                            </Typography>
                        </CardContent>
                        <CardActions>
                               <>
                                {!file.dir && 
                                <IconButton edge="end" onClick={() => handleShare(file)}>
                                    <FileCopyIcon />
                                </IconButton>}
                                {file.name!=='..' && 
                                <IconButton edge="end" onClick={() => handleEdit(file)}>
                                    <EditIcon />
                                </IconButton>}
                                {file.name!=='..' && 
                                <IconButton edge="end" onClick={() => handleDelete(file)}>
                                    <DeleteIcon />
                                </IconButton>}
                                </>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default FileCardView;
