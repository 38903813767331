import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, Typography } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import JsonEditor from './JsonEditor';

const categories = [
    '1. Promoting peace',
    '2. Fighting disease',
    '3. Providing clean water, sanitation, and hygiene',
    '4. Saving mothers and children',
    '5. Supporting education',
    '6. Growing local economies',
    '7. Protecting the environment',
    'Other'
    ];

const AdminDialogCrud = ({ open, onClose, onSubmit, isEditMode, columns, currentItem, setCurrentItem }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{isEditMode ? 'Edit Item' : 'Add Item'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {isEditMode ? 'Update the item details.' : 'Enter the details of the new item.'}
                </DialogContentText>
                {columns.map((column, index) => {
                    switch (column.type) {
                        case 'lineedit':
                        case 'multilineedit':
                        case 'img':
                            return (
                                <TextField
                                    key={column.field}
                                    autoFocus
                                    margin="dense"
                                    id={column.field}
                                    label={column.headerName}
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    value={currentItem[column.field]}
                                    onChange={(e) => setCurrentItem({ ...currentItem, [column.field]: e.target.value })}
                                    multiline={column.type === 'multilineedit'}
                                    minRows={column.type === 'multilineedit' ? 3 : 1}
                                />
                            );
                        case 'json':
                        return (
                            <>
                                {/* <div>currentItem[column.field]={currentItem[column.field]}</div> */}
                                <JsonEditor
                                    key={column.field}
                                    data={currentItem[column.field]}
                                    onChange={(updatedJson) => {
                                        console.log('Updated JSON:', updatedJson);
                                        setCurrentItem((prevItem) => {
                                            const newItem = {
                                                ...prevItem,
                                                [column.field]: updatedJson,
                                            };
                                            console.log('Updated currentItem:', newItem);
                                            return newItem;
                                        });
                                    }}
                                />
                            </>
                        );

                        case 'comboCategory':
                            return (
                                <FormControl fullWidth sx={{ marginTop: '8px' }} key={column.field}>
                                    <Typography variant="subtitle1" sx={{ marginBottom: '4px' }}>{column.headerName}</Typography>
                                    <Select
                                        id={column.field}
                                        value={currentItem[column.field]}
                                        onChange={(e) => setCurrentItem({ ...currentItem, [column.field]: e.target.value })}
                                    >
                                        {categories.map((category, index) => (
                                            <MenuItem key={index} value={category}>{category}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        default:
                            return <div key={index}>======= unknown field type ==</div>;
                    }
                    return null;
                })}

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSubmit}>{isEditMode ? 'Update' : 'Add'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AdminDialogCrud;
