import React, { useState } from 'react';
import { TextField, Button, Container, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';

const CreateGoogleForm = () => {
    const [title, setTitle] = useState('');
    const [spreadsheetId, setSpreadsheetId] = useState(process.env.googleTableId);
    const [loading, setLoading] = useState(false);
    const [formUrl, setFormUrl] = useState('');

    const handleSubmit = async () => {
        setLoading(true);
        try {
        const response = await axios.post(process.env.REACT_APP_GOOGLE_APPS_SCRIPT_URL, null, {
            params: { title, spreadsheetId }
        });
        setFormUrl(response.data);
        } catch (error) {
        console.error('Error creating form:', error);
        } finally {
        setLoading(false);
        }
  };

  return (
    <Container>
        exampleurl=https://docs.google.com/spreadsheets/d/1AOspsGebEaBXM2bY_9Qme4Tw47hCFxCc0x5p0AiG7Mg/edit?gid=1760013738#gid=1760013738 <br/>
        вв={process.env.googleTableId}
      <Typography variant="h4" gutterBottom>Create Google Form</Typography>
      <TextField
        label="Form Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Spreadsheet ID"
        value={spreadsheetId}
        onChange={(e) => setSpreadsheetId(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Create Form'}
      </Button>
      {formUrl && (
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          Form created: <a href={formUrl} target="_blank" rel="noopener noreferrer">Open Form</a>
        </Typography>
      )}
    </Container>
  );
};

export default CreateGoogleForm;
