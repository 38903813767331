import React, { useState } from "react";
import { Box, Typography, Container } from '@mui/material';
import ScrollTrigger from 'react-scroll-trigger';
import { Table, TableRow, TableCell } from '@mui/material';

import LandingBlock from './LandingBlock';
import { FormattedMessage } from 'react-intl';
import LandingHeader from './LandingHeader';
import LandingContentItem from './LandingContentItem';

const LandingArrContentBlock = ({ id, arrItem, designType, designmode }) => {
    const [isImageVisible, setImageVisible] = useState(false);
    const [isContentVisible, setContentVisible] = useState(false);

    const isEvenId = id % 2 === 0;
    let mode;
    if (designmode) {
        mode = designmode;
    } else {
        switch (designType) {
            case 'business':
                mode = isEvenId ? "light2" : "dark2";
                break;
            case 'chicago':
                mode = isEvenId ? "light3" : "dark3";
                break;
            case 'simple':
                mode = isEvenId ? "light5" : "dark3";
                break;
            case 'simple2':
                    mode = isEvenId ? "light5" : "light";
                    break;
            default:
                mode = isEvenId ? "light2" : "light";
        }
    }
    const imagePosition = (id % 2 === 0 ? "left" : "right");

    const handleImageScrollProgress = (progress) => {
        if (progress.progress > 0.2) {
            setImageVisible(true);
        }
    };

    const handleContentScrollProgress = (progress) => {
        if (progress.progress > 0.1) {
            setContentVisible(true);
        }
    };
    const showArrContent = (arrcontent) => {
        return (
            arrcontent && arrcontent.map((contArr, index) => (
                <>
                {contArr.tablecontent && 
                    <>
                    {contArr.header && <LandingHeader>{contArr.header}</LandingHeader>}
                    {showTableContent(contArr.tablecontent)}
                    </>
                }
                {(contArr.content || contArr.cards || contArr.PhotoCarusel || contArr.PhotoCarusel_15x10 || contArr.VideoCarusel || contArr.LandingCardEvent ) && showArrContent2(contArr, index)}
                </>
            ))
        )
    }

    const showArrContent2 = (contArr, index) => {
        return (
                <LandingContentItem id={index} landItem={contArr} />
        )
    }
    const showTableContent = (tablecontent) => {
        return (
            <Table>
                {tablecontent && tablecontent.map((row, index) => (
                <TableRow>
                {row.tablerow && row.tablerow.map((cell, index) => (
                    <TableCell key={index}>
                        {cell.LandingMessage}
                    </TableCell>
                    ))
                }
                </TableRow>
                ))
            }
            
            </Table>
        )
    }

    return (
        <LandingBlock mode={mode}>
            <Box className="section text-left">
                <Container>
                    {arrItem.header && <Box>
                        <LandingHeader designType={designType} >
                        <FormattedMessage id={arrItem.header} />
                        </LandingHeader>
                    </Box>}
                    {showArrContent(arrItem.arrcontent)}
                </Container>
            </Box>
        </LandingBlock>
    );
    
};

export default LandingArrContentBlock;
