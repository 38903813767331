
import axios from 'axios';

const SERVERURL = 'https://apifs.rotaryd2232ukraine.org/storage';

export const uploadFileToFStorage = async (filename, storagePath) => {
    
    const token = localStorage.getItem('tokenFS');
    console.log(`handleUpload token=${token}`);

    const formData = new FormData();
    formData.append('file', filename);
    formData.append('path', storagePath);

    console.log(`handleUpload filename=${filename.name}`);
    console.log(`handleUpload path=${storagePath}`);

    try {
        const response = await axios.post(`${SERVERURL}/upload`, formData, {
            headers: {
            'Storage-Token': token,
            'Content-Type': 'multipart/form-data'
            }
        });
        console.log('File uploaded successfully!');
    } catch (error) {
        console.error('File upload failed!', error);
    }
}