import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import './Calendar.css'; // Імпортуємо CSS
import {getEventColor} from './components/BlueAvatar';
import moment from 'moment';

import {apiGetEvents} from '../../api_sequelize/api_events';

const isPastEvent = (endDate) => moment(endDate).isBefore(moment(), 'day');

const GoogleCalendar3 = ({ filters, setEventCounts }) => {
    const [events, setEvents] = useState([]);
  
    useEffect(() => {
      const fetchEvents = async () => {
        try {
          const fetchedEvents = await apiGetEvents();
          console.log(`fetchEvents count=${fetchedEvents.length}`);
  
          // Обробка та фільтрація подій
          const filteredEvents = fetchedEvents.filter(event => {
            if (filters.district && event.colorId === '1') return true;
            if (filters.club && event.colorId === '2') return true;
            if (filters.governor && event.colorId === '3') return true;
            return false;
          });
  
          setEvents(filteredEvents);
  
          // Оновлення кількості подій кожного типу
          const eventCounts = {
            district: fetchedEvents.filter(event => event.colorId === '1').length,
            club: fetchedEvents.filter(event => event.colorId === '2').length,
            governor: fetchedEvents.filter(event => event.colorId === '3').length,
          };
          setEventCounts(eventCounts);
        } catch (error) {
          console.error('Error fetching events:', error);
        }
      };
  
      fetchEvents();
    }, [filters, setEventCounts]);
  

    return (
      <Box sx={{ p: 2 }}>
        <Box sx={{ mt: 2 }}>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={events.map(event => ({
              id: event.id,
              title: event.title,
              start: event.start,
              end: event.end,
              description: event.description,
              backgroundColor: getEventColor(event.colorId, isPastEvent(event.end)), 
              textColor: '#FFFFFF',  // Колір тексту
              extendedProps: {
                organizer: event.organizer,
                htmlLink: event.htmlLink,
                colorId: event.colorId
              }
            }))}
            eventDidMount={eventDidMountHandler}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            buttonText={{
              today: 'Today',
              month: 'Month',
              week: 'Week',
              day: 'Day'
            }}
            eventContent={renderEventContent} // Використання кастомного контенту для події
          />
        </Box>
      </Box>
    );
  };

  // Функція для застосування стилів після рендерингу події
  const eventDidMountHandler = (info) => {
    const color = getEventColor(info.event.extendedProps.colorId, isPastEvent(info.event.end));
    info.el.style.backgroundColor = color;
    info.el.style.borderColor = color;
    info.el.style.color = '#FFFFFF'; // колір тексту
  };
  
  const renderEventContent = (eventInfo) => {
    const { organizer, htmlLink } = eventInfo.event.extendedProps;
    return (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        <i>{eventInfo.event.title} {eventInfo.event.description && <>({eventInfo.event.description})</>}</i>
        {organizer && <p>{organizer.displayName}</p>}
        {htmlLink && (
          <a href={htmlLink} target="_blank" rel="noopener noreferrer">Event Link</a>
        )}
      </div>
    );
  };
  
  export default GoogleCalendar3;