import React, { useEffect, useState, useRef } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { getAllUsers, updateUser } from '../../api_sequelize/api_eventusers';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box
} from '@mui/material';
import generatePdf from '../../modules/PDF/PdfGenerator';
import successSound from '../../assets/audio/scanner-beep.mp3';
import regSound from '../../assets/audio/sci_fi_door.mp3';
import unregSound from '../../assets/audio/beam.mp3';
import './QRScanner.css'; // Підключаємо файл зі стилями


const QRScanner = () => {
  const [res, setRes] = useState(null);
  const [res2, setRes2] = useState(null);
  const [users, setUsers] = useState([]);
  const [manualUser, setManualUser] = useState(null);
  const audioRef = useRef(null);
  const audioRef2 = useRef(null);
  const audioRef3 = useRef(null);

  useEffect(() => {
    fetchUsers();

    const qrCodeScanner = new Html5QrcodeScanner(
      "reader",
      { fps: 10, qrbox: 250 },
      false
    );
    qrCodeScanner.render(onScanSuccess, onScanFailure);

    async function onScanSuccess(decodedText, decodedResult) {
      console.log(`Code matched = ${decodedText}`, decodedResult);
      if (decodedText) {    
        audioRef.current.play();
        setRes(decodedText);
        const params = new URL(decodedText).searchParams;
        const userid = Number(params.get('userid'));
        setRes2(userid);
        register(userid, 'yes');
      }
    }

    function onScanFailure(error) {
      //console.warn(`Code scan error = ${error}`);
    }

    return () => {
      qrCodeScanner.clear().catch(error => {
        console.error('Failed to clear QR Code scanner', error);
      });
    };
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await getAllUsers();
      console.log('Fetched users:', response.data);
      setUsers(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
    }
    return null;
  };

  const register = async (userid, needregister) => {
    
    const us = await fetchUsers();

    const foundUser = us.find(user => {
      console.log(`Checking user.user_id: ${user.user_id} against userid: ${userid}`);
      return Number(user.user_id) === Number(userid);
    }) || null;
    console.log(`found userid=${userid} user=${JSON.stringify(foundUser)} l=${us && us.length}`);
    if (foundUser) {
      try {
        setManualUser(foundUser);
        foundUser.tags = needregister;        
        console.log(`foundUser =${JSON.stringify(foundUser)}`);
        const response = await updateUser(userid, foundUser);
        console.log('User updated:', response.data);
        fetchUsers(); // Refresh user list after creating a new user
        setRes("success");
        if (needregister)
            audioRef2.current.play();
        else
            audioRef3.current.play();
      } catch (error) {
        console.error('Error updating user:', error);
      }
    }
  };

  const handleReg = () => {
    register(manualUser.user_id, 'yes');
  };

  const handleDownloadPdf = () => {
    generatePdf(users);
  };

  const handleRowClick = (user) => {
    setManualUser(user);
  };

  const handleReset = () => {
    register(manualUser.user_id, null);
  };
  const handleOK = () => {
    setManualUser(null);
    setRes(null);
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div" align="center">
                Scan QR Code
              </Typography>
              <Box id="reader" sx={{ width: '100%', mt: 2 }} />
              <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                Result: {res}
              </Typography>
              {res && (
                <Typography align="center" color="success.main">
                  Учасника "{manualUser && <>{manualUser.first_name} {manualUser.last_name}</>}" зареєстровано
                </Typography>
              )}
              {manualUser && <>{manualUser.first_name} {manualUser.last_name}</>}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button variant="contained" color="primary" onClick={handleReg} sx={{ flexGrow: 1 }} disabled={!manualUser} >
                  Зареєструвати вручну
                </Button>
                <Button variant="contained" color="error" onClick={handleReset} sx={{ width: '100px', ml: 2 }} disabled={!manualUser} >
                  &#10006;
                </Button>
                <Button variant="contained" color="primary" onClick={handleOK} sx={{ width: '100px', ml: 2 }} disabled={!manualUser} >
                  OK
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Users List
          </Typography>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users && users.length > 0 ? (
                  users.map((user) => (
                    <TableRow 
                      key={user.user_id} 
                      onClick={() => handleRowClick(user)}
                      className={`${user.tags === 'yes' ? 'tagged-row' : ''} ${manualUser && manualUser.user_id === user.user_id ? 'selected-row' : ''}`}
                    >
                      <TableCell>{user.user_id}</TableCell>
                      <TableCell>{user.first_name}</TableCell>
                      <TableCell>{user.last_name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No users found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Button variant="contained" color="secondary" fullWidth sx={{ mt: 2 }} onClick={handleDownloadPdf}>
            Скачати PDF
          </Button>
        </Grid>
      </Grid>
      <audio ref={audioRef} src={successSound} />
      <audio ref={audioRef2} src={regSound} />
      <audio ref={audioRef3} src={unregSound} />
    </Container>
  );
};

export default QRScanner;
