const LandingPdf = ({ title, link }) => {
    const googleDocsUrl = `https://docs.google.com/gview?url=${encodeURIComponent(link)}&embedded=true`;
  
    return (
      <div>
        {/* <h2>{title}</h2> */}
        <iframe
          src={googleDocsUrl}
          style={{ width: '100%', height: '1200px', border: 'none' }}
          title="PDF Viewer"
        />
      </div>
    );
  };
  
  export default LandingPdf;
  