import React from 'react';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';

const BtnDownloadFrame = ({ style, children, downloadUrl }) => {
    const buttonStyle = {
        position: 'absolute',
        top: '60px',
        right: '12px',
        zIndex: 10,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderRadius: '6px',
        color: 'lightgray'
    };

    const frameStyle = {
        position: 'relative',
        ...style,
    };

    // Функція для завантаження файлу
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = fixGoogleLink(downloadUrl); // Використовуємо функцію для виправлення посилання
        link.download = 'file.pdf'; // Назва файлу для завантаження
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Функція для виправлення посилання Google Drive
    const fixGoogleLink = (link) => {
        if (!link) return null;

        if (link.includes("https://drive.google.com/file/d/")) {
            const fileId = link.match(/\/d\/(.*)\//)[1];
            return `https://drive.google.com/uc?export=download&id=${fileId}`;
        } else if (link.includes("https://drive.google.com/open?id=")) {
            const fileId = link.split('id=')[1];
            return `https://drive.google.com/uc?export=download&id=${fileId}`;
        }

        return link;
    };

    return (
        <div style={frameStyle}>
            <IconButton
                style={buttonStyle}
                onClick={handleDownload}
                aria-label="Download PDF"
            >
                <GetAppIcon />
            </IconButton>
            {children}
        </div>
    );
};

export default BtnDownloadFrame;
