// services/projectService.js
import axios from 'axios';

const API_URL = 'https://api-sequelize.rotaryd2232ukraine.org/db';


// Отримання всіх мовних версій проекту за mproj_id
export const getMultilangProjects = async (mprojId) => {
  try {
    const response = await axios.get(`${API_URL}/mprojects`, {
      params: { mproj: mprojId },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching multilang projects:', error);
    throw error;
  }
};

// Отримання одного мультимовного проекту
export const getMultilangProject = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/mprojects/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching multilang project:', error);
    throw error;
  }
};

// Оновлення мультимовного проекту
export const updateMultilangProject = async (id, multilangProject) => {
  try {
    const response = await axios.put(`${API_URL}/mprojects/${id}`, multilangProject);
    return response.data;
  } catch (error) {
    console.error('Error updating multilang project:', error);
    throw error;
  }
};

// Видалення мультимовного проекту
export const deleteMultilangProject = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/mprojects/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting multilang project:', error);
    throw error;
  }
};

// Отримання всіх мовних версій проекту за mproj_id з фільтрацією за мовою
export const getMultilangProjectsByLang = async (mprojId, lang) => {
    try {
      const response = await axios.get(`${API_URL}/mprojects_lang`, {
        params: { mproj: mprojId, lang: lang },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching multilang projects by lang:', error);
      throw error;
    }
  };

  // Отримання всіх мовних версій проекту за currlang з фільтрацією за мовою
export const getProjectsByLang = async (lang) => {
    try {
      const response = await axios.get(`${API_URL}/mprojects_lang3`, {
        params: { lang: lang },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching projects by lang:', error);
      throw error;
    }
  };

//   // Оновлення мовних версій проекту за mproj_id та мовою
// export const updateMultilangProjectsByLang = async (mprojId, lang, projectData) => {
//     try {
//       const response = await axios.put(`${API_URL}/mprojects_plang`, projectData, {
//         params: { mproj: mprojId, lang: lang },
//       });
//       return response.data;
//     } catch (error) {
//       console.error('Error updating projects by lang:', error);
//       throw error;
//     }
//   };

  // Оновлення або створення мовних версій проекту за mproj_id та мовою
export const updateOrCreateMultilangProjectByLang = async (mprojId, lang, projectData) => {
    try {
        if (projectData && projectData.youtube && typeof projectData.youtube === 'object') {
            projectData.youtube = JSON.stringify(projectData.youtube);
        }
        

        const response = await axios.put(`${API_URL}/mprojects`, projectData, {
            params: { mproj: mprojId, lang: lang },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating or creating project by lang:', error);
        throw error;
    }
  };


  // Створення нового мультимовного проекту
export const createMultilangProject = async (projectData) => {
    try {
      const response = await axios.post(`${API_URL}/projects/multilang`, projectData);
      return response.data;
    } catch (error) {
      console.error('Error creating multilang project:', error);
      throw error;
    }
  };