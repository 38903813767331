import React, { useEffect, useState } from 'react';
import MapGL, { Source, Layer, Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Box } from '@mui/material';

import { getAllClubs } from '.././../api_sequelize/api_rclubs';
import { coord } from './coord'; // Імпорт координат

const MAPBOX_TOKEN = 'pk.eyJ1IjoidHlrb2dtMiIsImEiOiJjbHp4eHN2ZXIwejkzMmtzMnd1NzlmbmFwIn0.uHLLDghqAjo34FqE1hmPxw';

const geojson = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        color: '#FF0000',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          // Додайте правильні координати областей України
        ],
      },
    },
    // Додайте більше областей тут
  ],
};

const layerStyle = {
  id: 'regions',
  type: 'fill',
  paint: {
    'fill-color': ['get', 'color'],
    'fill-opacity': 0.5,
  },
};

const bounds = [
  [20.0, 44.0], // Південно-західна точка України
  [40.2, 51.4], // Північно-східна точка України
];

const mapStyle = 'mapbox://styles/mapbox/light-v10'; // Використовуйте стандартний стиль Mapbox

const ClubsMapMapGL = ({ filterRType }) => {
  const [groupedClubs, setGroupedClubs] = useState([]);

  useEffect(() => {
    LoadData();
  }, [filterRType]);

  const LoadData = async () => {
    try {
      const dbcl = await getAllClubs();
      let filteredClubs = dbcl
        .filter(club => club.clubcat !== "interact")
        .filter(club => !club.clubname.startsWith("Satellite "))
        .filter(club => !club.clubname.startsWith("Сателітний "))
        .filter(club => club.clublang !== "UKR");

      if (filterRType) {
        filteredClubs = filteredClubs.filter(club => club.clubcat === filterRType);
      }

      const groupedByCity = {};

      filteredClubs.forEach(club => {
        const cityNameLowerCase = club.clubcity.toLowerCase(); // Перетворення назви міста в нижній регістр
        const cityCoord = coord.find(c => c.city.toLowerCase() === cityNameLowerCase);

        if (cityCoord) {
          // Якщо місто знайдено, додаємо до відповідної групи
          const cityKey = cityCoord.city.toLowerCase();
          if (!groupedByCity[cityKey]) {
            groupedByCity[cityKey] = {
              city: cityCoord.city,
              coordinates: cityCoord.coordinates,
              count: 0,
            };
          }
          groupedByCity[cityKey].count += 1;
        } else {
          // Якщо місто не знайдено, додаємо до групи "Ternopil"
          const cityKey = 'ternopil';
          if (!groupedByCity[cityKey]) {
            console.log(`=== ungrouped=${cityNameLowerCase}`);
            groupedByCity[cityKey] = {
              city: 'Ternopil',
              coordinates: [25.5918, 49.5535], // Координати для Ternopil
              count: 0,
            };
          }
          groupedByCity[cityKey].count += 1;
        }
      });

      setGroupedClubs(Object.values(groupedByCity));
    } catch (error) {
      console.error('Помилка при завантаженні даних:', error);
    }
  };

  const getClubWord = (count) => {
    if (count === 1) return 'клуб';
    if (count >= 2 && count <= 4) return 'клуби';
    return 'клубів';
  };

  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
      <MapGL
        initialViewState={{
          latitude: 48.3794,
          longitude: 31.1656,
          zoom: 5,
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle={mapStyle}
        mapboxAccessToken={MAPBOX_TOKEN}
        maxBounds={bounds} // Обмеження карти межами України
      >
        <Source id="regions" type="geojson" data={geojson}>
          <Layer {...layerStyle} />
        </Source>

        {groupedClubs.map((club, index) => (
          <Marker
            key={index}
            longitude={club.coordinates[0]}
            latitude={club.coordinates[1]}
          >
            <div style={{ textAlign: 'center', color: 'black', fontSize: '1.5em' }}>
              {filterRType === 'rotary' && (
                <img src="https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/RotaryMBS-Simple_CMYK-C.png" alt={club.city} height={40} />
              )}
              {filterRType === 'rotaract' && (
                <img src="https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/rotaract_simple.png" alt={club.city} height={40} />
              )}
              {!filterRType && (
                <img src="https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/1707638852340-506809438.png" alt={club.city} height={40} />
              )}
              <div>
                {club.city} ({club.count} {getClubWord(club.count)})
              </div>
            </div>
          </Marker>
        ))}
      </MapGL>
    </Box>
  );
};

export default ClubsMapMapGL;
