import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import openSansFont from '../../fonts/OpenSans-Regular.ttf';

const generatePdf = async (users) => {
  const pdfDoc = await PDFDocument.create();
  pdfDoc.registerFontkit(fontkit);

  // Завантаження шрифту з файлу
  const fontBytes = await fetch(openSansFont).then((res) => res.arrayBuffer());
  const font = await pdfDoc.embedFont(fontBytes);

  // Завантаження логотипів
  const leftLogoUrl = 'https://7fc65ea596c38701.cdn.express/secure/v-BUICOSVNm9gnJulFGs8Q/clubsitecreator/D2232/Events/RYLA/RYLA_Logo_large_v3.png';
  const rightLogoUrl = 'https://7fc65ea596c38701.cdn.express/secure/e94Cs1RC4TMxpHDtWHoQLg/clubsitecreator/D2232/Logo/1707237910091-833961273.png';
  const leftLogoBytes = await fetch(leftLogoUrl).then(res => res.arrayBuffer());
  const rightLogoBytes = await fetch(rightLogoUrl).then(res => res.arrayBuffer());
  const leftLogo = await pdfDoc.embedPng(leftLogoBytes);
  const rightLogo = await pdfDoc.embedPng(rightLogoBytes);

  // A4 горизонтальний
  const page = pdfDoc.addPage([841.89, 595.28]);
  const fontSize = 12;

  const { width, height } = page.getSize();
  const margin = 50;

  // Розмір логотипів
  const logoHeight = 80;
  const leftLogoWidth = (leftLogo.width / leftLogo.height) * logoHeight;
  const rightLogoHeight = logoHeight / 1.5;
  const rightLogoWidth = (rightLogo.width / rightLogo.height) * rightLogoHeight;

  // Додавання логотипів
  page.drawImage(leftLogo, {
    x: margin,
    y: height - margin - logoHeight,
    width: leftLogoWidth,
    height: logoHeight,
  });
  page.drawImage(rightLogo, {
    x: width - margin - rightLogoWidth,
    y: height - margin - rightLogoHeight,
    width: rightLogoWidth,
    height: rightLogoHeight,
  });

  // Додавання заголовку
  page.drawText('Список учасників', {
    x: margin + leftLogoWidth + 20,
    y: height - margin - logoHeight / 2 + 10,
    size: fontSize + 6,
    font,
    color: rgb(0.2, 0.4, 0.6),
  });

  // Додавання поточної дати
  const currentDate = new Date().toLocaleDateString('uk-UA');
  page.drawText(`Дата: ${currentDate}`, {
    x: margin,
    y: height - margin - logoHeight - 20,
    size: fontSize,
    font,
    color: rgb(0, 0, 0),
  });

  // Відступ між датою та таблицею
  const tableTopY = height - margin - logoHeight - 60;
  const headerHeight = 20;
  const rowHeight = 20;

  // Ширини колонок
  const numberColumnWidth = 40; // Втричі вужча
  const defaultColumnWidth = 120;
  const emailColumnWidth = 240; // Вдвічі ширша

  // Додавання заголовків таблиці з фоном і сіткою
  const headers = ['№', 'Ім\'я', 'Прізвище', 'Email', 'Зареєстрований'];
  const columnWidths = [numberColumnWidth, defaultColumnWidth, defaultColumnWidth, emailColumnWidth, defaultColumnWidth];

  headers.forEach((header, i) => {
    const x = margin + columnWidths.slice(0, i).reduce((a, b) => a + b, 0);
    // Малювання фону заголовків таблиці
    page.drawRectangle({
      x,
      y: tableTopY,
      width: columnWidths[i],
      height: headerHeight,
      color: rgb(0.7, 0.85, 1),
    });
    // Малювання тексту заголовків таблиці
    page.drawText(header, {
      x: x + 5,
      y: tableTopY + 5,
      size: fontSize,
      font,
      color: rgb(0, 0, 0),
    });
    // Малювання сітки заголовків таблиці
    page.drawRectangle({
      x,
      y: tableTopY,
      width: columnWidths[i],
      height: headerHeight,
      borderColor: rgb(0.8, 0.8, 0.8),
      borderWidth: 0.5,
    });
  });

  // Додавання рядків таблиці з сіткою
  users.forEach((user, rowIndex) => {
    const y = tableTopY - (rowIndex + 1) * rowHeight;
    const values = [
      rowIndex + 1, // Порядковий номер
      user.first_name,
      user.last_name,
      user.email,
      user.tags,
    ];

    const rowBackgroundColor = user.tags === 'yes' ? rgb(0.9, 1, 0.9) : null;

    if (rowBackgroundColor) {
      page.drawRectangle({
        x: margin,
        y,
        width: columnWidths.reduce((a, b) => a + b, 0),
        height: rowHeight,
        color: rowBackgroundColor,
      });
    }

    values.forEach((value, colIndex) => {
      const x = margin + columnWidths.slice(0, colIndex).reduce((a, b) => a + b, 0);
      // Малювання сітки таблиці
      page.drawRectangle({
        x,
        y,
        width: columnWidths[colIndex],
        height: rowHeight,
        borderColor: rgb(0.8, 0.8, 0.8),
        borderWidth: 0.5,
      });
      // Малювання тексту у клітинках таблиці
      page.drawText(value !== null && value !== undefined ? value.toString() : '', {
        x: x + 5,
        y: y + 5,
        size: fontSize,
        font,
        color: rgb(0.3, 0.3, 0.3), // Темно-сірий колір тексту
      });
    });
  });

  const pdfBytes = await pdfDoc.save();

  // Create a blob and trigger download
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'users_list.pdf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default generatePdf;
    