

import {List, ListItem, ListItemText, ListItemIcon, IconButton } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const FileListView = ({
                files,
                shareLinks,
                handleItemClick,
                handleShare,
                handleEdit,
                handleDelete
}) => {
    return (
        <List>
                        {files.map(file => (
                            <ListItem key={file.name}>
                                <ListItemIcon onClick={() => handleItemClick(file)}>
                                    {file.dir ? <FolderIcon /> : <InsertDriveFileIcon />}
                                </ListItemIcon>

                                {file.media === 'image' && <img src={shareLinks[file.name]} height='30px' alt={file.name} />}

                                <ListItemText primary={file.name} onClick={() => handleItemClick(file)} />
                                <>
                                    {!file.dir && 
                                    <IconButton edge="end" onClick={() => handleShare(file)}>
                                        <FileCopyIcon />
                                    </IconButton>}
                                    {file.name!=='..' && 
                                    <IconButton edge="end" onClick={() => handleEdit(file)}>
                                        <EditIcon />
                                    </IconButton>}
                                    {file.name!=='..' && 
                                    <IconButton edge="end" onClick={() => handleDelete(file)}>
                                        <DeleteIcon />
                                    </IconButton>}
                                </>
                            </ListItem>
                        ))}
                    </List>
    )
}

export default FileListView;