import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Paper, Divider } from '@mui/material';
import moment from 'moment';
import { alpha } from '@mui/material/styles';

import LinkIcons from './components/LinkIcons';

import {apiGetEvents} from '../../api_sequelize/api_events';
import {getSubfieldValue} from '../../modules/CRUD_system/functions/MetafieldEditLineTools';
import Avatar from '@mui/material/Avatar';
import BlueAvatar from './components/BlueAvatar';

const CalendarList = ({ filters, setEventCounts }) => {
  const [events, setEvents] = useState([]);
  const lastPastEventRef = useRef(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const fetchedEvents = await apiGetEvents();

        // Обробка та фільтрація подій
        const filteredEvents = fetchedEvents.filter(event => {
          if (filters.district && event.colorId === '1') return true;
          if (filters.club && event.colorId === '2') return true;
          if (filters.governor && event.colorId === '3') return true;
          return false;
        });

        // Сортування подій за датою початку
        filteredEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

        setEvents(filteredEvents);

        // Оновлення кількості подій кожного типу
        const eventCounts = {
          district: fetchedEvents.filter(event => event.colorId === '1').length,
          club: fetchedEvents.filter(event => event.colorId === '2').length,
          governor: fetchedEvents.filter(event => event.colorId === '3').length,
        };
        setEventCounts(eventCounts);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, [filters, setEventCounts]);

  useEffect(() => {
    if (lastPastEventRef.current) {
      lastPastEventRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [events]);

  const formatDate = (date) => moment(date).format('DD.MM.YYYY');
  const isPastEvent = (endDate) => moment(endDate).isBefore(moment(), 'day');

  return (
    <Box sx={{ p: 2 }}>
      <Paper elevation={0} sx={{ p: 2, backgroundColor: '#fff' }}>
        <List>
          {events.map((event, index) => {
            const pastEvent = isPastEvent(event.end);
            const isLastPastEvent = pastEvent && (index === events.length - 1 || !isPastEvent(events[index + 1]?.end));

            return (
              <React.Fragment key={event.id}>
                <ListItem
                  alignItems="flex-start"
                  sx={{ color: pastEvent ? 'gray' : 'inherit' }}
                  ref={isLastPastEvent ? lastPastEventRef : null}
                >
                    <BlueAvatar colorId={event.colorId} pastEvent={pastEvent}/>
                  <ListItemText
                    primary={
                      <>
                        <Typography variant="h6" component="span" sx={{ color: pastEvent ? 'gray' : 'darkblue' }}>
                          {event.title}
                        </Typography>
                      </>
                    }
                    secondary={
                      <>
                        <Typography variant="body2" component="span" sx={{ marginLeft: 1, color: pastEvent ? 'gray' : 'blue' }}>
                            ({formatDate(event.start)} - {formatDate(event.end)})
                        </Typography>
                        <Typography component="span" variant="body2" color="text.primary">
                        &nbsp;{event.description}
                        </Typography>
                        {event.organizer && ` — ${event.organizer.displayName}`}
                        {/* {event.htmlLink && (
                          <a href={event.htmlLink} target="_blank" rel="noopener noreferrer" style={{ color: pastEvent ? 'gray' : 'blue', marginLeft: 10 }}>
                            Посилання на подію
                          </a>
                        )} */}

                        <LinkIcons 
                            regicon={getSubfieldValue(event, 'additionalFields:regLink')} 
                            webicon={getSubfieldValue(event, 'additionalFields:siteLink')} 
                            docsicon={getSubfieldValue(event, 'additionalFields:docsLink')} 
                            photoicon={getSubfieldValue(event, 'additionalFields:photoLink')} 
                            videoicon={getSubfieldValue(event, 'additionalFields:videoLink')} 
                            pastEvent={pastEvent}
                        />
                      </>
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      </Paper>
    </Box>
  );
};

export default CalendarList;
