import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Container, Paper } from '@mui/material';

import AdminSites from './AdminSites';
import AdminSiteMenus from './AdminSiteMenus';
import AdminSitePages from './AdminSitePages';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const AdminSitesEditor = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="admin tabs"
        >
          <Tab label="Сайти" />
          <Tab label="Меню" />
          <Tab label="Сторінка" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <AdminSites />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AdminSiteMenus />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AdminSitePages />
        </TabPanel>
      </Paper>
    </Container>
  );
};

export default AdminSitesEditor;
