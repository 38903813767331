export const getMenuStructure = (local) => {
    return data_eng;
}

const data_eng = {

    navbar: {
        _title: "РеК Україна",
        logoUrl: 'https://7fc65ea596c38701.cdn.express/secure/tvYykQdztChGGWY4lntflA/clubsitecreator/rc_newlevel/logo/Logo_RC_NewLevel(1).png',
        // languages: [
        //     "en-US",
        //     "ua-UA",
        // ],
        // autorization: "true",
        addlogoborder: true,
        withoutd2232url: true, 
        menu: [
            {
                title: "Адміністрування",
                arr: [
                    { 
                        title: "Ротарійці клубу",
                        lref: '/rc_newlevel/adminmember',
                    },
                    { 
                        title: "Проєкти клубу",
                        lref: '/rc_newlevel/adminprojects',
                    },
                    { 
                        title: "Файлове сховище",
                        lref: '/rc_newlevel/adminfiles',
                        disabled: true,
                    },
                ]
            },
            {
                title: "Сервіси",
                arr: [
                    { 
                        title: "QR-код генератор",
                        lref: '/rc_newlevel/qrcodegen',
                    },
                    { 
                        title: "Події Клубу",
                        disabled: true,
                        lref: '/rd_2232/adminevents',
                    },
                ]
            },
        ],
    },
    footer: {
        contactPerson_: {
            name: "Priamry contacts:",
            email: "rotaryeclubua@gmail.com",
        },
    }
}
