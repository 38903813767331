import axios from 'axios';

const API_BASE_URL = 'https://api-sequelize.rotaryd2232ukraine.org/db'; 

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const getAllClubs = async () => {
    console.log('======== getAllClubs');
  try {
    const response = await api.get('/clubs');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch clubs:', error);
    throw error;
  }
};

export const getClubById = async (id) => {
  try {
    const response = await api.get(`/club/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch club with id ${id}:`, error);
    throw error;
  }
};

export const createClub = async (clubData) => {
  try {
    const response = await api.post('/club', clubData);
    return response.data;
  } catch (error) {
    console.error('Failed to create club:', error);
    throw error;
  }
};

export const updateClub = async (id, clubData) => {
  try {
    const response = await api.put(`/club/${id}`, clubData);
    return response.data;
  } catch (error) {
    console.error(`Failed to update club with id ${id}:`, error);
    throw error;
  }
};

export const deleteClub = async (id) => {
  try {
    await api.delete(`/club/${id}`);
  } catch (error) {
    console.error(`Failed to delete club with id ${id}:`, error);
    throw error;
  }
};
