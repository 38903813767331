import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import './JsonEditor.css';
import LandingContent from '../../components/LandingContent';

const JsonEditor = ({ data, onChange }) => {
    console.log('Initializing JsonEditor with data:', data);

    const [text, setText] = useState(() => {
        try {
            return JSON.stringify(JSON.parse(data), null, 2);
        } catch (error) {
            console.error('Failed to parse JSON during initialization:', error);
            return '';
        }
    });
    const [error, setError] = useState(null);
    const [isFullscreen, setIsFullscreen] = useState(false);

    useEffect(() => {
        console.log('JsonEditor useEffect called with data:', data);
        try {
            setText(JSON.stringify(JSON.parse(data), null, 2));
            setError(null);
        } catch (error) {
            console.error('Failed to parse JSON during useEffect:', error);
            setText('');
            setError('Invalid JSON');
        }
    }, [data]);

    const handleChange = (e) => {
        const newText = e.target.value;
        console.log('Text changed:', newText);
        setText(newText);
        try {
            const parsedJson = JSON.parse(newText);
            setError(null);
            console.log('Parsed JSON:', parsedJson);
            onChange?.(newText); // Передаємо JSON як рядок
        } catch (err) {
            setError('Invalid JSON');
        }
    };

    const toggleFullscreen = () => {
        setIsFullscreen((prev) => !prev);
    };

    const formatJson = () => {
        try {
            const parsedJson = JSON.parse(text);
            const formattedJson = JSON.stringify(parsedJson, null, 2);
            setText(formattedJson);
            setError(null);
            onChange?.(formattedJson); // Передаємо відформатований рядок
        } catch (err) {
            setError('Invalid JSON');
        }
    };

    return (
        <div className={isFullscreen ? 'fullscreen' : ''}>
            <Button onClick={toggleFullscreen}>
                {isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen'}
            </Button>
            <Box display="flex" flexDirection="row" width="100%" height="100%" gap={1}>
                <Box flexGrow={1} style={{ width: '100%', transition: 'width 0.2s' }}>
                    <textarea
                        value={text}
                        onChange={handleChange}
                        rows="20"
                        cols="50"
                        style={{ width: '100%', height: isFullscreen ? '80vh' : '400px' }}
                    />
                    {error && <div className="error-message">{error}</div>}
                    <Button onClick={formatJson} variant="contained" color="primary" sx={{ marginTop: '8px' }}>
                        Format JSON
                    </Button>
                </Box>
                {isFullscreen && (
                    <Box flexGrow={1} style={{ width: '100%', transition: 'width 0.2s' }}>
                        <LandingContent data={JSON.parse(text)?.page_json} />
                    </Box>
                )}
            </Box>
        </div>
    );
};

export default JsonEditor;
