import React from 'react';
import { Container, Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography } from '@mui/material';

const LandingEventProgramTable = ({ title, program }) => {
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '...';
        }
        return text;
      };

  return (
    <Container component={Paper} sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        {title}<br/>
      </Typography>
      {program && program.map((day, index) => (
        <div key={index} style={{ marginBottom: '2rem' }}>
          <Typography variant="h6" gutterBottom>
            {day.date}
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Час</TableCell>
                <TableCell>Подія</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {day.events.map((event, idx) => (
                <TableRow key={idx}>
                  <TableCell>{event.time}</TableCell>
                  <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                    {event.details}  {event.link && <a href={event.link} >Посилання</a>} {event.link2 && <a href={event.link2} >Замовити</a>}
                    {event.site1 && <><br/><a href={event.site1} >{truncateText(event.site1, 35)}</a></>}
                    {event.site2 && <><br/><a href={event.site2} >{event.site2}</a></>}
                    {event.site3 && <><br/><a href={event.site3} >{event.site3}</a></>}
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}
    </Container>
  );
};

export default LandingEventProgramTable;
