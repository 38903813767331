import axios from 'axios';
import CryptoJS from 'crypto-js';

const SERVERURL = 'https://apifs.rotaryd2232ukraine.org/storage';

export const fsFetchFiles = async (path) => {
    console.log('fetchFiles ...');
    const token = localStorage.getItem('tokenFS');
    try {
        const response = await axios.post(`${SERVERURL}/list`, { path }, {
        headers: {
            'Storage-Token': token,
            'Content-Type': 'application/json'
        }
        });
        const arrFiles = response.data.callback;
        if (Array.isArray(arrFiles)) {
        return arrFiles;
        } else {
        console.error('Response data is not an array:', response.data);
        return null;
        }
    } catch (error) {
        console.error('Failed to fetch files:', error);
        return null;
    }
};

export const fsCreateFolder = async (path, newFolderName) => {
    const token = localStorage.getItem('tokenFS');
    try {
        await axios.post(`${SERVERURL}/create-folder`, {
            path,
            dir_name: newFolderName
        }, {
            headers: {
            'Storage-Token': token,
            'Content-Type': 'application/json'
            }
        });
        //onPathChange(path); // Refresh the list
        return true;
    } catch (error) {
        console.error('Failed to create folder:', error);
        return false;
    }
};

export const fsRenameItem = async (path, oldFile, newName) => {
    const token = localStorage.getItem('tokenFS');
    try {
      await axios.post(`${SERVERURL}/rename`, {
        path,
        old_name: oldFile.name,
        new_name: newName,
        type: oldFile.dir ? 'folder' : 'file'
      }, {
        headers: {
          'Storage-Token': token,
          'Content-Type': 'application/json'
        }
      });
      //onPathChange(path); // Refresh the list
      return true;
    } catch (error) {
      console.error('Failed to rename item:', error);
      return false;
    }
  };

export const fsDeleteItem = async (path, oldName) => {
    console.log('deleteItem ...');
    const token = localStorage.getItem('tokenFS');
    try {
        await axios.post(`${SERVERURL}/delete`, {
            path,
            items: [oldName]
        }, {
            headers: {
            'Storage-Token': token,
            'Content-Type': 'application/json'
            }
        });
        
        return true;
    } catch (error) {
        console.error('Failed to delete item:', error);
        return false;
    }
};

// export const fsShareFiles = async (path) => {
//     console.log('fetchFiles ...');
//     const token = localStorage.getItem('tokenFS');
//     try {
//         const response = await axios.post(`${SERVERURL}/share`, { path }, {
//         headers: {
//             'Storage-Token': token,
//             'Content-Type': 'application/json'
//         }
//         });
//         const sharelink = response.data.link;
//         console.error('Response data is not an array:', response.data);
//         return sharelink;
//     } catch (error) {
//         console.error('Failed to fetch files:', error);
//         return null;
//     }
// };

// export const fsGetFile = async (path) => {
//     console.log('fsGetFile ...');
//     const token = localStorage.getItem('tokenFS');
//     try {
//         const response = await axios.post(`${SERVERURL}/share2`, { path }, {
//         headers: {
//             'Storage-Token': token,
//             'Content-Type': 'application/json'
//         }
//         });
//         const sharelink = response.data;
//         console.log('Response link:', response);
//         return sharelink;
//     } catch (error) {
//         console.error('Failed to fetch files:', error);
//         return null;
//     }
// };

export const fsSecureLink = async (path) => {
    console.log('fsSecureLink ...');

    const sanitizePath = (path) => {
        // Видаляє початковий слеш, якщо він є
        return path.startsWith('/') ? path.slice(1) : path;
      };
    const path2 = sanitizePath(path);
    
    // const response = await axios.get(`https://rotary.org.ua/seclink.php?path=${path2}`);
    // const secureLink = response.data.secure_link;
    // console.log('Response path:', path2);
    // console.log('Response secureLink:', secureLink);
    // return secureLink;

    const generateSecureLink = (path) => {
        const hash = CryptoJS.MD5(secret + path);
        let md5 = CryptoJS.enc.Base64.stringify(hash);
        md5 = md5.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return `${cdnBase}${md5}/${path}`;
      };

    const cdnBase = "https://7fc65ea596c38701.cdn.express/secure/";
    //const path = "example-directory/my-file.jpg";
    const secret = "5dbaf1fd8eb3c5ac3fa85e1f0bfbae51";

    const secureLink = generateSecureLink(path2);
    console.log('Response secureLink:', secureLink);
    return secureLink;

    // const token = localStorage.getItem('tokenFS');
    // const response_ip = await axios.get('https://api.ipify.org?format=json');
    // //const myip = response_ip.data.ip;
    // console.log('fsSecureLink path:', path);
    // try {
    //     const response = await axios.post(`${SERVERURL}/securelink`, { path }, {
    //     headers: {
    //         'Storage-Token': token,
    //         'Content-Type': 'application/json'
    //     }
    //     });
    //     const sharelink = response.data;
    //     console.log('Response link:', response);
    //     return sharelink;
    // } catch (error) {
    //     console.error('Failed to fetch files:', error);
    //     return null;
    // }
};