import React from 'react';
import Box from '@mui/material/Box';
import './LandingBlock.css';

const LandingBlock = ({ mode, children }) => {
    let blockClass;

    switch (mode) {
    case 'dark':
        blockClass = 'landing-block-dark';
        break;
    case 'light':
        blockClass = 'landing-block-light';
        break;
    case 'light2':
        blockClass = 'landing-block-light2';
        break;
    case 'dark2': 
        blockClass = 'landing-block-dark2';
        break;
    case 'dark2-sport': 
        blockClass = 'landing-block-dark2-sport';
        break;
    case 'light3':
        blockClass = 'landing-block-light3';
        break;
    case 'dark3':
        blockClass = 'landing-block-dark3';
        break;
    default:
        blockClass = 'landing-block-light'; 
        break;
    }
    
    return (
        <Box className={`landing-block ${blockClass}`}>
            {children}
        </Box>
    );
}

export default LandingBlock;