
import { Box, Container, Grid, Typography } from '@mui/material'
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import GlobeIcon from '@mui/icons-material/Public';
import ImageIcon from '@mui/icons-material/Image';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

// import MainPage from "../components/Main";
// import LandingBlock from '../components/LandingBlock';
// import LandingHeader from '../components/LandingHeader';
// import LAccordionItem from '../components/LAccordionItem';

import GOOGLEFORM_PNG from '../../../assets/img/icons/google-form.png';
// import GOOGLEFORM_D_PNG from '../assets/img/icons/google-form_d2.png';
import PPT_PNG from '../../../assets/img/icons/ppt.png';
import PPT_D_PNG from '../../../assets/img/icons/ppt_d2.png';

const LinkIcon = ({ tooltip, link, iconbtn, disabled=false}) => {

    return (
        <Tooltip title={tooltip}>
            <IconButton 
                onClick={() => window.open(link, '_blank')} 
                aria-label={tooltip}
                color="primary"
                disabled={disabled}
            >
                {iconbtn}            
            </IconButton>
        </Tooltip>
    )
}

const LinkIcons = ({ regicon, webicon, docsicon, pastEvent, photoicon, videoicon }) => {

    return (
        <Box bgcolor='white'>
            {regicon && <LinkIcon disabled={pastEvent} tooltip='Форма реєстрації' link={regicon} iconbtn={<img src={pastEvent?PPT_D_PNG:GOOGLEFORM_PNG} alt="Google Form" style={{ width: '20px', height: '20px' }} />} />}
            {webicon && <LinkIcon tooltip='Сторінка події' link={webicon} iconbtn={<GlobeIcon />} />}
            {docsicon && <LinkIcon tooltip='Презентації з події' link={docsicon} iconbtn={<img src={PPT_PNG} alt="Google Form" style={{ width: '24px', height: '24px' }} />} />}
            {photoicon && <LinkIcon tooltip='Фотогалерея' link={photoicon} iconbtn={<ImageIcon />} />}
            {videoicon && <LinkIcon tooltip='Відееозапис' link={photoicon} iconbtn={<OndemandVideoIcon />} />}
        </Box>
    )
}

export default LinkIcons;