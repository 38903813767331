import axios from 'axios';

const API_BASE_URL = 'https://api-sequelize.rotaryd2232ukraine.org/db'; 

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

//====================================================================
//tyko_site_data table
export const getAllSites = async () => {
    console.log('======== getAllSites');
  try {
    const response = await api.get('/sites');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch sites:', error);
    throw error;
  }
};

export const getSiteById = async (id) => {
  try {
    const response = await api.get(`/site/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch site with id ${id}:`, error);
    throw error;
  }
};

export const getSiteByUrl = async (url) => {
    try {
        const response = await api.get(`/site_byurl/${url}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch site with url ${url}:`, error);
        throw error;
    }
};

export const createSite = async (siteData) => {
  try {
    const response = await api.post('/site', siteData);
    return response.data;
  } catch (error) {
    console.error('Failed to create site:', error);
    throw error;
  }
};

export const updateSite = async (id, siteData) => {
  try {
    const response = await api.put(`/site/${id}`, siteData);
    return response.data;
  } catch (error) {
    console.error(`Failed to update site with id ${id}:`, error);
    throw error;
  }
};

export const deleteSite = async (id) => {
  try {
    await api.delete(`/site/${id}`);
  } catch (error) {
    console.error(`Failed to delete site with id ${id}:`, error);
    throw error;
  }
};

//====================================================================
//tyko_sitemenu_data table
export const getAllSiteMenus = async () => {
    console.log('======== getAllSiteMenus');
  try {
    const response = await api.get('/sitemenus');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch sitemenus:', error);
    throw error;
  }
};

export const getSiteMenusBySiteId = async (id) => {
    try {
        const response = await api.get(`/sitemenu_bysiteid/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch sitemenu with id ${id}:`, error);
        throw error;
    }
};

export const createSiteMenu = async (siteData) => {
    try {
      const response = await api.post('/sitemenu', siteData);
      return response.data;
    } catch (error) {
      console.error('Failed to create sitemenu:', error);
      throw error;
    }
  };
  
  export const updateSiteMenu = async (id, siteData) => {
    try {
      const response = await api.put(`/sitemenu/${id}`, siteData);
      return response.data;
    } catch (error) {
      console.error(`Failed to update sitemenu with id ${id}:`, error);
      throw error;
    }
  };
  
  export const deleteSiteMenu = async (id) => {
    try {
      await api.delete(`/sitemenu/${id}`);
    } catch (error) {
      console.error(`Failed to delete sitemenu with id ${id}:`, error);
      throw error;
    }
  };

  //====================================================================
//tyko_site_pages table
export const getAllSitePages = async () => {
    console.log('======== getAllSitePages');
  try {
    const response = await api.get('/sitepages');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch sitepages:', error);
    throw error;
  }
};

export const getSitePagesBySiteId = async (id) => {
    try {
        const response = await api.get(`/sitepage_bysiteid/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch sitepage with id ${id}:`, error);
        throw error;
    }
};

export const getSitePagesByUrl = async (url) => {
    try {
        const response = await api.get(`/sitepage_byurl/${url}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch sitepage with url ${url}:`, error);
        throw error;
    }
};

export const createSitePage = async (siteData) => {
    try {
      const response = await api.post('/sitepage', siteData);
      return response.data;
    } catch (error) {
      console.error('Failed to create sitepage:', error);
      throw error;
    }
  };
  
  export const updateSitePage = async (id, siteData) => {
    try {
      const response = await api.put(`/sitepage/${id}`, siteData);
      return response.data;
    } catch (error) {
      console.error(`Failed to update sitepage with id ${id}:`, error);
      throw error;
    }
  };
  
  export const deleteSitePage = async (id) => {
    try {
      await api.delete(`/sitepage/${id}`);
    } catch (error) {
      console.error(`Failed to delete sitepage with id ${id}:`, error);
      throw error;
    }
  };